<template>
  <v-card>
    <v-fab-transition>
      <v-btn
        @click="quitOptions"
        v-if="!petitesOptions"
        color="error"
        fab
        small
        right
        top
        class="mt-9"
        absolute
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-fab-transition>

    <div class="options-container">
      <v-list subheader v-if="!petitesOptions">
        <v-subheader>Général</v-subheader>
        <!-- DARK MODE AUTO -->
        <v-list-item class="pl-8">
          <v-checkbox
            v-model="autodarkSelect"
            label="Dark Mode Auto"
            hint="Prioriété au réglage de votre appareil."
            persistent-hint
          >
          </v-checkbox>
        </v-list-item>

        <!-- DEFAULT HOME -->
        <v-list-item class="pl-5" style="text-align: left;">
          <v-col>
            <p>Page d'accueil par défaut</p>

            <v-btn-toggle v-model="defaultHomePageSelect" mandatory>
              <v-btn>
                <v-icon>mdi-calendar-blank-outline</v-icon>
              </v-btn>

              <v-btn>
                <v-icon>mdi-timelapse</v-icon>
              </v-btn>

              <v-btn>
                <v-icon>mdi-globe-model</v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-list-item>

        <!-- RESTORE OPTIONS -->
        <v-list-item>
          <v-btn
            :color="confirmRestor ? 'red lighten-2' : ''"
            small
            text
            @click="confirmRestor ? restoration() : preRestoration()"
          >
            <v-icon class="mr-3">
              {{ confirmRestor ? "mdi-restore-alert" : "mdi-restore" }}
            </v-icon>
            {{
              confirmRestor
                ? "Restaurer les paramètres ?"
                : "Restaurer les paramètres"
            }}
          </v-btn>
        </v-list-item>
      </v-list>

      <!-- 
//              ___     __        ___ 
//  |\ | |  | |  |     /  \ |\ | |__  
//  | \| \__/ |  |     \__/ | \| |___ 
//                                    
 -->
      <!-- IF /NUIT  -->
      <v-list subheader v-if="$route.name === 'Today'">
        <v-divider></v-divider>
        <v-subheader>Options d'affichage</v-subheader>
        <v-list-item>
          <v-checkbox v-model="showAltitudeSelect" label="Altitude">
          </v-checkbox>
        </v-list-item>
      </v-list>

      <!-- 
//   __   __         __        __   __  
//  /  ` /  \  |\/| |__)  /\  /__` /__` 
//  \__, \__/  |  | |    /~~\ .__/ .__/ 
//                                      
 -->
      <!-- IF /COMPASS -->
      <v-list subheader v-if="$route.name === 'Compass'" flat>
        <v-divider v-if="!petitesOptions"></v-divider>

        <v-subheader v-if="!petitesOptions">Options d'affichage</v-subheader>

        <v-list-item class="pl-8 mb-5" v-if="!petitesOptions">
          <v-checkbox
            v-model="autoNightSelect"
            label="Night Auto"
            hint="Les astres ne s'afficheront que lorsque le soleil est couché."
            persistent-hint
          >
          </v-checkbox>
        </v-list-item>
        <v-spacer></v-spacer>
        <v-subheader>
          <v-btn
            text
            dense
            color="secondary"
            class="my-0 font-weight-regular text-overline"
            @click="$store.commit('PETITES_OPTIONS_TOGGLE', !petitesOptions)"
          >
            <v-icon small left>
              {{ petitesOptions ? "mdi-chevron-up" : "mdi-chevron-down" }}
            </v-icon>
            {{
              petitesOptions ? "options générales" : "paramètres d'affichage"
            }}
          </v-btn>
        </v-subheader>

        <!-- ETOILES / CONSTELL / PLENETS -->
        <!-- ETOILES / CONSTELL / PLENETS -->
        <!-- ETOILES / CONSTELL / PLENETS -->
        <v-list-item
          v-for="object in compassoptionsList"
          :key="object.id"
          v-show="petitesOptions"
        >
          <!-- SHOW ON/OFF -->
          <v-list-item-content class="py-0">
            <v-btn
              class="px-2"
              style="text-transform: none !important; font-weight: normal"
              text
              @click="optionToggle(object.id, 'show')"
              :color="
                object.show
                  ? ''
                  : $vuetify.theme.dark
                  ? 'grey'
                  : 'grey darken-1'
              "
            >
              <v-icon class="mr-4"> {{ object.icon }} </v-icon>
              <v-list-item-title class="text-left">
                {{ object.title }}
              </v-list-item-title>
              <v-spacer></v-spacer>
              <v-icon>
                {{ object.show ? "mdi-eye" : "mdi-eye-off" }}
              </v-icon>
            </v-btn>
          </v-list-item-content>

          <!-- LABEL ON/OFF -->
          <v-list-item-action class="ma-0">
            <v-btn
              icon
              :disabled="!object.show || !('label' in object)"
              @click="optionToggle(object.id, 'label')"
            >
              <v-icon v-if="'label' in object">
                {{
                  object.label ? "mdi-label-outline" : "mdi-label-off-outline"
                }}
              </v-icon>
            </v-btn>
          </v-list-item-action>

          <!-- VARIATION I/II/III/IV -->
          <v-list-item-action class="ma-0" v-if="plusDeChoix">
            <v-btn
              icon
              :disabled="!object.show || !('label' in object)"
              @click="variationToggle(object.id, object.variation)"
            >
              <v-icon v-if="'variation' in object">
                {{ getVariationIcon(object.variation) }}
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <!-- PLUS DE CHOIX -->
        <!-- PLUS DE CHOIX -->
        <!-- PLUS DE CHOIX -->
        <v-subheader v-if="!plusDeChoix && petitesOptions">
          <v-btn
            text
            dense
            color="secondary"
            class="my-0 font-weight-regular text-overline"
            @click="$store.commit('PLUSDECHOIX_TOGGLE', true)"
          >
            <v-icon small left> mdi-plus </v-icon>
            plus de choix
          </v-btn>
        </v-subheader>
        <v-divider v-if="plusDeChoix && petitesOptions"></v-divider>
        <!-- 
        v-show="!object.prime && plusDeChoix" -->

        <!-- TRAJECTOIRES -->
        <!-- TRAJECTOIRES -->
        <!-- TRAJECTOIRES -->
        <v-list-item v-show="plusDeChoix && petitesOptions">
          <v-list-item-content class="py-0">
            <v-btn
              class="px-2"
              style="text-transform: none !important; font-weight: normal;"
              text
              @click="toggleTr()"
              :color="
                !statusTr.off
                  ? ''
                  : $vuetify.theme.dark
                  ? 'grey'
                  : 'grey darken-1'
              "
            >
              <v-icon class="mr-4"> mdi-sine-wave </v-icon>
              <v-list-item-title class="text-left">
                Trajectoires
              </v-list-item-title>
              <v-spacer></v-spacer>
              <v-icon
                dense
                v-for="icon in statusTr.icons"
                :key="icon"
                class="mr-3"
              >
                {{ icon }}
              </v-icon>
              <v-icon>
                {{ !statusTr.off ? "mdi-eye" : "mdi-eye-off" }}
              </v-icon>
            </v-btn>
          </v-list-item-content>
        </v-list-item>

        <!-- GRILLE -->
        <!-- GRILLE -->
        <!-- GRILLE -->
        <v-list-item v-show="plusDeChoix && petitesOptions">
          <v-list-item-content class="py-0">
            <v-btn
              class="px-2"
              style="text-transform: none !important; font-weight: normal;"
              text
              @click="optionToggle('graticule', 'show')"
              :color="
                compassoptions.graticule.show
                  ? ''
                  : $vuetify.theme.dark
                  ? 'grey'
                  : 'grey darken-1'
              "
            >
              <v-icon class="mr-4">
                {{
                  compassoptions.graticule.show ? "mdi-grid" : "mdi-grid-off"
                }}
              </v-icon>
              <v-list-item-title class="text-left">
                Grille
              </v-list-item-title>
              <v-spacer></v-spacer>
              <v-icon>
                {{ compassoptions.graticule.show ? "mdi-eye" : "mdi-eye-off" }}
              </v-icon>
            </v-btn>
          </v-list-item-content>
        </v-list-item>

        <!-- LABEL GRATICULE -->
        <v-list-item v-show="plusDeChoix && petitesOptions">
          <v-list-item-content class="py-0">
            <v-btn
              class="px-2"
              style="text-transform: none !important; font-weight: normal;"
              text
              @click="optionToggle('graticule', 'label')"
            >
              <v-icon class="mr-4"> mdi-compass-rose </v-icon>
              <v-list-item-title class="text-left">
                Graduation
              </v-list-item-title>
              <v-spacer></v-spacer>
              <v-text class="text-caption">
                {{
                  compassoptions.graticule.label
                    ? "NORD - NE - EST"
                    : "N - 15° - 30° - NE"
                }}
              </v-text>
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>

    <!-- SNACK BAR -->
    <!-- <v-snackbar top v-model="infobar" timeout="2000" color='white' light v-show="infotext != ''">
      {{ infotext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="{infobar = false}">
          Close
        </v-btn>
      </template>
    </v-snackbar> -->
  </v-card>
</template>

<script>
export default {
  name: "optionModal",
  data: () => ({
    autodarkSelect: true,
    showAltitudeSelect: false,
    confirmRestor: false,
    autoNightSelect: true,
    defaultHomePageSelect: 2,
    // infobar: false,
    // infotext: "",
  }),
  beforeMount: function() {
    this.autodarkSelect = this.autodark;
    this.showAltitudeSelect = this.showAltitude;
    this.autoNightSelect = this.compassoptions.autoNight;
    this.defaultHomePageSelect = this.defaultHomePage;
  },
  mounted: function() {
    this.plusDeChoix = false;
  },

  watch: {
    defaultHomePageSelect: function() {
      this.$store.commit("DEFAULTHOME_UPDATE", this.defaultHomePageSelect);
    },
    autodarkSelect: function() {
      this.$store.commit("AUTOTHEME_UPDATE", this.autodarkSelect);
      // if (this.autodark) {
      //   this.$vuetify.theme.dark = window.matchMedia(
      //     "(prefers-color-scheme: dark)"
      //   ).matches;
      // }
    },
    showAltitudeSelect: function() {
      this.$store.commit("SHOWALTI_UPDATE", this.showAltitudeSelect);
    },
    autoNightSelect: function() {
      this.$store.commit("AUTONIGHT_TOGGLE", this.autoNightSelect);
    },
  },
  methods: {
    optionToggle(object, option) {
      this.$store.commit("OPTION_TOGGLE", { object, option });
    },
    variationToggle(object, variation) {
      let newVariation = variation ? (variation >= 2 ? 0 : variation + 1) : 1;
      this.$store.commit("VARIATION_TOGGLE", {
        object: object,
        newVariation: newVariation,
      });
      if (object == "constellations") {
        this.$store.commit("OPTION_TRUE", {
          object: "constellations",
          option: "label",
        });
      }
      // this.infotext='';
      // this.showVariationInfo(object, newVariation);
    },

    // showVariationInfo(object, newVariation) {
    //   this.infobar = true;
    //   switch (object) {
    //     case "stars":
    //       switch (newVariation) {
    //         case 2:
    //           this.infotext = "5000 étoiles";
    //           break;
    //         case 1:
    //           this.infotext = "1200 étoiles";
    //           break;
    //         case 0:
    //           this.infotext = "500 étoiles";
    //           break;
    //         default:
    //           this.infotext = "error";
    //           break;
    //       }
    //       break;
    //     case "constellations":
    //       switch (newVariation) {
    //         case 2:
    //           this.infotext = "toutes les constellations";
    //           break;
    //         case 1:
    //           this.infotext = "la plupart des constellations";
    //           break;
    //         case 0:
    //           this.infotext = "seulement les constellations principales";
    //           break;
    //         default:
    //           this.infotext = "error";
    //           break;
    //       }
    //   }
    // },

    quitOptions() {
      this.$emit("stopOptions");
    },

    preRestoration() {
      this.confirmRestor = true;
    },

    restoration() {
      if (
        window.confirm(
          "Réinitialisation des options (les villes seront sauvegardées)"
        )
      ) {
        localStorage.removeItem("options");
        this.$store.dispatch("resetOptions");
        this.autodarkSelect = this.autodark;
        this.showAltitudeSelect = this.showAltitude;
        this.autoNightSelect = this.compassoptions.autoNight;
        this.defaultHomePageSelect = this.defaultHomePage;
        this.confirmRestor = false;
      } else {
        this.confirmRestor = false;
        return;
      }
    },
    toggleTr() {
      let moonShow = this.compassoptions.moonTr.show;
      let sunShow = this.compassoptions.sunTr.show;

      if (moonShow && sunShow) {
        this.optionToggle("sunTr", "show");
        this.optionToggle("moonTr", "show");
        return;
      }
      if (!moonShow && sunShow) {
        this.optionToggle("moonTr", "show");
        return;
      } else {
        this.optionToggle("sunTr", "show");
        return;
      }
    },
    getVariationIcon(variation) {
      switch (variation) {
        case 0:
          return "mdi-roman-numeral-1";
        case 1:
          return "mdi-roman-numeral-2";
        case 2:
          return "mdi-roman-numeral-3";
        default:
          return "mdi-ufo-outline";
      }
    },
  },
  computed: {
    showAltitude() {
      return this.$store.state.options.one.showAltitude;
    },
    autodark() {
      return this.$store.state.options.global.autodark;
    },
    defaultHomePage() {
      return this.$store.state.options.global.defaultHomePage;
    },
    compassoptions() {
      return this.$store.state.options.compass;
    },
    petitesOptions() {
      return this.$store.state.petitesOptions;
    },
    plusDeChoix() {
      return this.$store.state.plusDeChoix;
    },

    compassoptionsList() {
      return [
        {
          id: "stars",
          title: "Étoiles",
          icon: "mdi-star-four-points-outline",
          show: this.compassoptions.stars.show,
          label: this.compassoptions.stars.label,
          variation: this.compassoptions.stars.variation,
        },
        {
          id: "constellations",
          title: "Constellations",
          icon: "mdi-vector-polyline",
          show: this.compassoptions.constellations.show,
          label: this.compassoptions.constellations.label,
          variation: this.compassoptions.constellations.variation,
        },
        {
          id: "planets",
          title: "Planètes",
          // icon: "mdi-death-star",
          icon: "mdi-atom-variant",
          show: this.compassoptions.planets.show,
          label: this.compassoptions.planets.label,
          variation: this.compassoptions.planets.variation,
        },
      ];
    },

    statusTr() {
      let moonShow = this.compassoptions.moonTr.show;
      let sunShow = this.compassoptions.sunTr.show;
      let result = { off: false, icons: [] };

      if (moonShow && sunShow) {
        result.id = 2;
        result.off = false;
        result.icons = ["mdi-weather-night", "mdi-weather-sunny"];
      }
      if (!moonShow && sunShow) {
        result.id = 1;
        result.off = false;
        result.icons = ["mdi-weather-sunny"];
      }
      if (moonShow && !sunShow) {
        result.id = 1;
        result.off = false;
        result.icons = ["mdi-weather-night"];
      }
      if (!moonShow && !sunShow) {
        result.id = 0;
        result.off = true;
        result.icons = [];
      }

      return result;
    },

    // statusGrid() {
    //   let gridVariation = this.compassoptions.graticule.variation;
    //   let icons = [];
    //   switch(gridVariation) {
    //     case 0:
    //       icons= ['mdi-reorder-horizontal']
    //       break;
    //     case 1:
    //       icons= ['mdi-reorder-horizontal','mdi-reorder-vertical']
    //       break;
    //     case 2:
    //       icons= ['mdi-reorder-horizontal','mdi-reorder-vertical','mdi-numeric']
    //       break;
    //   }
    //   return icons
    // },
  },
};
</script>

<style scoped>
.options-container {
  overflow: scroll;
  height: 100%;
}
</style>
