<template>
  <v-container
    style="
  max-width: 400px;"
  >
    <div class="blanc" :style="$vuetify.theme.dark ? 'background-color : #121212' : 'background-color : white'"></div>
    <v-row>
      <v-col cols="12" class="text-left text-justify">
        <v-card>
          <v-card-title class="text-h5 text--primary pb-6">
            Installation de l'application
          </v-card-title>

          <v-card-subtitle class="text-subtitle-2 pb-0"
            >sur iOS</v-card-subtitle
          >
          <v-card-text>
            cliquer sur le bouton "Partager"
            <v-icon dense> mdi-export-variant </v-icon> <br />puis sur "Sur
            l'écran d'accueil" <v-icon dense> mdi-plus-box-outline </v-icon>
          </v-card-text>
        </v-card>
        <v-card class="mt-10">
          <v-card-title class="text-h5 text--primary">
            Crédits
          </v-card-title>
          <v-card-text> © 2021 - Léo Piskor </v-card-text>
          <v-card-text>
            Merci <span class="font-weight-bold">@mbostock</span> pour
            <span class="font-weight-bold">d3</span> <br />
            Merci <span class="font-weight-bold">@mourner</span> pour
            <span class="font-weight-bold">suncalc</span> <br />
            Merci <span class="font-weight-bold">@ofrohn</span> pour
            <span class="font-weight-bold">d3-celestial</span> <br />
            Merci <span class="font-weight-bold">@mivion</span> pour
            <span class="font-weight-bold">ephemeris</span> <br />
            Merci <span class="font-weight-bold">@klinquist</span> pour
            <span class="font-weight-bold">tz-lookup</span>
          </v-card-text>
        </v-card>
        <v-card class="mt-10">
          <v-card-title class="text-h5 text--primary">
            Bug Report
          </v-card-title>
          <v-card-text class="font-weight-black">
            nuitblancheapp@gmail.com
          </v-card-text>
        </v-card>
        <v-card class="mt-16 text-right">
          <v-card-text>
            Nuit Blanche © 2021 - V2.2.6
          </v-card-text>
        </v-card>
        <!-- <v-card class="mt-10">
          <v-card-title class="text-h5 text--primary">
            Newsletter
          </v-card-title>
          <v-card-text>
            On ne sait jamais
          </v-card-text>
          <v-card-action>
            <v-btn text link href="https://mailchi.mp/0d66c8c54f2c/rk4st6y0yb">
              Cliquer ici pour vous inscrires
            </v-btn>
          </v-card-action>
        </v-card> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped>
.blanc {
  width: 100%;
  height: 60px;
  position: fixed;
  top:0;
  left:0;
  z-index: 2;
}
</style>
