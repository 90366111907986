<template>
  <div class="lock-screen">
    <div class="content">
      <div class="parent">
        <!-- HEAD -->

        <div class="head">
          <svg
            class="svg-moon"
            viewBox="0 0 200 50"
            :height="daheight < 580 ? 60 : undefined"
          >
            <g transform="translate(100,25)">
              <circle class="contour-moon" r="20px" />
              <path class="forme-moon" :d="moonPhase" />
            </g>
          </svg>
          <!-- <v-transition-scroll-x-reverse-transition> -->
          <h3 @click="gotToCal" class="primary--text">
            <span>{{ title }}</span>
          </h3>
          <!-- </v-transition-scroll-x-reverse-transition> -->
        </div>
        <!-- CHART -->
        <div
          class="chart-container"
          :style="{ height: `${chartContainerHeightpx}` }"
        >
          <!-- AXIS LEFT -->
          <div class="y-axis" v-show="showAltitude">
            <svg width="100%" height="100vh">
              <rect
                width="25"
                :height="chartHeight + spaceAround.top"
                fill="transparent"
              />
              <g
                id="yMoonAxis"
                :transform="axisYposition"
                :color="$vuetify.theme.dark ? 'white' : '#383840'"
              />
            </svg>
          </div>

          <!-- OVERFLOW -------------------------------------------->

          <div
            id="timeLine"
            class="scroll-container"
            @scroll="onScroll"
            @mousedown="dragstart"
            @mousemove="drag"
            @mouseup="dragend"
            @mouseleave="dragleave"
            @wheel="cancelMomentumTracking"
          >
            <svg class="svgoverflow" :width="overwidth">
              <!-- COLOR PATH -->
              <g>
                <path
                  v-for="item in SunDATA"
                  :key="item.key"
                  :d="item.path"
                  :stroke-width="colorHeight"
                  :stroke="
                    $vuetify.theme.dark ? item.color.dark : item.color.light
                  "
                />
              </g>

              <!-- MOON LINE -->
              <g :transform="moonLineYposition">
                <clipPath id="graphClip">
                  <rect x="0" y="0" :width="overwidth" :height="chartHeight" />
                </clipPath>

                <path
                  class="moon-line"
                  :d="moonLine"
                  clip-path="url(#graphClip)"
                />
              </g>

              <!-- SUN RISE / SUN SET / LEGENDE -->
              <g>
                <text
                  v-for="time in sunriseEtSunset"
                  :key="time.key + 700"
                  :x="x(time.start)"
                  :y="spaceAround.top - 11"
                  class="legend"
                  :fill="$vuetify.theme.dark ? 'white' : '#383840'"
                >
                  {{
                    (time.start.getHours() > 12 ? "☼ ↧ " : "☼ ↥ ") +
                      showHour(time.start)
                  }}
                </text>
                <path
                  v-for="time in sunriseEtSunset"
                  :key="time.key + 100"
                  class="legend-tick"
                  :d="tickPath(time, 'sun')"
                  stroke="#383840"
                />
              </g>

              <!-- MOON RISE / MOON SET / LEGENDE -->
              <g>
                <text
                  v-for="time in moonTimes"
                  :key="moonTimes.indexOf(time) + 400"
                  :x="x(time.time)"
                  :y="spaceAround.top + chartHeight - 20"
                  class="legend"
                  fill="white"
                >
                  {{
                    (time.id === "set" ? "☾ ↧ " : "☾ ↥ ") + showHour(time.time)
                  }}
                </text>
                <path
                  v-for="time in moonTimes"
                  :key="moonTimes.indexOf(time) + 900"
                  class="legend-tick"
                  :d="tickPath(time, 'moon')"
                  stroke="white"
                />
              </g>

              <!-- SKY COLOR / LEGENDE -->
              <g>
                <text
                  v-for="time in photoData"
                  :key="photoData.indexOf(time) + 300"
                  :y="textColorYposition(time)"
                  class="legend"
                  fill="white"
                >
                  <tspan
                    :x="
                      time.start.getHours() > 12
                        ? time.photo === 'Gold'
                          ? x(time.start) + 7
                          : x(time.end) - 7
                        : time.photo === 'Gold'
                        ? x(time.end) - 7
                        : x(time.start) + 7
                    "
                    :text-anchor="
                      time.start.getHours() > 12
                        ? time.photo === 'Gold'
                          ? 'start'
                          : 'end'
                        : time.photo === 'Gold'
                        ? 'end'
                        : 'start'
                    "
                  >
                    {{ time.photo }}
                  </tspan>
                  <tspan
                    :x="
                      time.start.getHours() > 12
                        ? time.photo === 'Gold'
                          ? x(time.start) + 7
                          : x(time.end) - 7
                        : time.photo === 'Gold'
                        ? x(time.end) - 7
                        : x(time.start) + 7
                    "
                    dy="1.2em"
                    :text-anchor="
                      time.start.getHours() > 12
                        ? time.photo === 'Gold'
                          ? 'start'
                          : 'end'
                        : time.photo === 'Gold'
                        ? 'end'
                        : 'start'
                    "
                  >
                    {{ showHour(time.start) }}
                  </tspan>
                  <tspan
                    :x="
                      time.start.getHours() > 12
                        ? time.photo === 'Gold'
                          ? x(time.start) + 7
                          : x(time.end) - 10
                        : time.photo === 'Gold'
                        ? x(time.end) - 10
                        : x(time.start) + 7
                    "
                    dy="1.2em"
                    :text-anchor="
                      time.start.getHours() > 12
                        ? time.photo === 'Gold'
                          ? 'start'
                          : 'end'
                        : time.photo === 'Gold'
                        ? 'end'
                        : 'start'
                    "
                  >
                    {{ showHour(time.end) }}
                  </tspan>
                </text>
              </g>

              <!-- AXIS BOTTOM -->
              <g
                id="xAxisQuart"
                :transform="axisXposition"
                :color="$vuetify.theme.dark ? 'white' : '#383840'"
              />
              <g
                id="xAxisHour"
                :transform="axisXposition"
                :color="$vuetify.theme.dark ? 'white' : '#383840'"
              />

              <g>
                <!-- TICKS midi et minuit -->
                <path
                  v-for="item in midiMinuit"
                  :key="item.key + 600"
                  :d="item.path"
                  stroke-width="1px"
                  stroke="white"
                />
                <text
                  v-for="item in midiMinuit"
                  class="tick-info"
                  :x="x(item.time) + 10"
                  :y="spaceAround.top + 20"
                  :key="item.key + 1100"
                >
                  {{ item.text }}
                </text>
              </g>
              <!-- NOW TICK -->
              <g>
                <circle
                  v-if="altiLine(maintenant()) > 0"
                  :cx="x(maintenant())"
                  :cy="yMoon(altiLine(maintenant())) + spaceAround.top"
                  r="7"
                  fill="none"
                  stroke="white"
                  stroke-width="2"
                ></circle>
                <path :d="nowPath()" stroke-width="1px" stroke="red" />
                <text
                  class="tick-info"
                  :x="x(maintenant()) + 10"
                  :y="nowYposition()"
                >
                  {{ maintenantText() }}
                </text>
              </g>
            </svg>
            <!--             FIN SVG               -->
          </div>
        </div>

        <!--  -->
        <!--  -->
        <!-- NAV -->
        <div class="nav-container">
          <div class="nav">
            <v-row justify="space-around">
              <v-btn text @click="prevDay(7)">
                <v-icon>
                  mdi-chevron-double-left
                </v-icon>
              </v-btn>
              <v-btn text @click="prevDay(1)">
                <v-icon>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              <v-btn color="error" text @click="goNow">
                <v-icon>
                  mdi-history
                </v-icon>
              </v-btn>
              <v-btn text @click="nextDay(1)">
                <v-icon>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
              <v-btn text @click="nextDay(7)">
                <v-icon>
                  mdi-chevron-double-right
                </v-icon>
              </v-btn>
            </v-row>

            <v-row justify="space-around">
              <v-btn
                text
                :large="daheight < 550 ? false : true"
                @click="scrollTo(timeToScrollTo.dawn)"
              >
                <v-icon>
                  mdi-weather-sunset-up
                </v-icon>
              </v-btn>
              <v-btn
                text
                :large="daheight < 550 ? false : true"
                @click="scrollTo(timeToScrollTo.gold)"
              >
                <v-icon>
                  mdi-weather-sunset-down
                </v-icon>
              </v-btn>
              <v-btn
                text
                :large="daheight < 550 ? false : true"
                @click="scrollTo(timeToScrollTo.nextDawn)"
              >
                <v-icon>
                  mdi-weather-sunset-up
                </v-icon>
              </v-btn>
              <!-- <v-btn
                text
                :large="daheight < 550 ? false : true"
                @click="plusDIXminutes()"
              >
                <v-icon>
                  mdi-square-outline
                </v-icon>
              </v-btn> -->

              <!-- <v-btn
                icon
                :large="daheight < 550 ? false : true"
                @click="prout"
              >
                <v-icon>
                  mdi-phone
                </v-icon>
              </v-btn> -->
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script scoped>
import * as d3 from "d3";
var suncalc = require("suncalc");
suncalc.addTime(-4, "blueHourEnd", "blueHourStart");

export default {
  name: "nuitOne",
  props: {},
  data: () => ({
    color: {
      gold: { light: "#FFCB7D", dark: "#FFCB7D" },
      // gold: { light: "#FFCB7D", dark: "#807E7E" },
      day: { light: "#CFECFF", dark: "#383839" },
      dusk: { light: "#64A1CC", dark: "#64A1CC" },
      // dusk: { light: "#64A1CC", dark: "#403F3F" },
      blue: { light: "#2787CC", dark: "#2787CC" },
      // blue: { light: "#2787CC", dark: "#595858" },
      astro: { light: "#185480", dark: "#185480" },
      // astro: { light: "#185480", dark: "#2B2B2B" },
      night: { light: "#383839", dark: "#202020" },
    },
    isItTomorrow: false,

    //25 en bas pour l'axe et 40 en haut pour les textes
    spaceAround: { top: 30, bottom: 30 },

    daheight: window.innerHeight,

    timeStamp: 0,
    counting: null,

    //DRAG VARIABLES
    isDown: false,
    startX: 0,
    scrollLeft: 0,
    velX: 0,
    momentumID: "",
  }),

  ///////////////////////////////// WATCH /////////////////////////
  ///////////////////////////////// WATCH /////////////////////////
  ///////////////////////////////// WATCH /////////////////////////
  ///////////////////////////////// WATCH /////////////////////////

  watch: {
    showAltitude: function() {
      return this.generateYaxis();
    },
  },

  ///////////////////////////////// MOUNTED /////////////////////////
  ///////////////////////////////// MOUNTED /////////////////////////
  ///////////////////////////////// MOUNTED /////////////////////////
  ///////////////////////////////// MOUNTED /////////////////////////

  mounted: function() {
    this.updateNow();
    this.generateAxis();
    this.generateYaxis();
    this.scrollTo(this.timeToScrollTo.gold);
    // this.goNow();
  },

  beforeDestroy: function() {
    clearInterval(this.counting);
  },

  ///////////////////////////////// METHODS /////////////////////////
  ///////////////////////////////// METHODS /////////////////////////
  ///////////////////////////////// METHODS /////////////////////////
  ///////////////
  ////////
  //////

  methods: {
    // plusDIXminutes(){
    //   this.maintenant = d3.timeMinute.offset(this.maintenant,10);
    //   this.goNow();
    // },

    maintenant() {
      return this.convertTZ(new Date());
    },

    updateNow() {
      this.counting = setInterval(() => {
        this.timeStamp += 1;
        console.log("timeStamp is " + this.timeStamp);
      }, 20000); // toutes les 20s
    },

    convertTZ(uneDate) {
      if (uneDate === undefined) {
        return undefined;
      }
      return new Date(uneDate.toLocaleString("en-US", { timeZone: this.tz }));
    },

    gotToCal() {
      this.$router.push("/calendar");
    },
    colorPath(d) {
      var x = this.x;
      return `M ${x(d.start)} 0 H ${x(d.end) + 1}`;
    },
    altiLine(d) {
      return (
        (suncalc.getMoonPosition(d, this.latitude, this.longitude).altitude *
          180) /
        Math.PI
      );
    },

    // EVERYTHING WE NEED TO DISPLAY THE NOW RED LINE
    nowPath() {
      this.timeStamp + 1;
      var y0 = this.spaceAround.top;
      var y1 = y0 + this.chartHeight;
      return `M ${this.x(this.maintenant())} ${y0 - 5} V ${y1}`;
    },

    maintenantText() {
      this.timeStamp + 1;
      var moonUp = suncalc.getMoonPosition(
        this.maintenant(),
        this.latitude,
        this.longitude
      );
      var compassMoon = Math.round((moonUp.azimuth * 180) / Math.PI + 180);
      var result =
        moonUp.altitude > 0
          ? compassMoon < 11.25
            ? "N"
            : compassMoon < 33.75
            ? "NNE"
            : compassMoon < 56.25
            ? "NE"
            : compassMoon < 78.75
            ? "ENE"
            : compassMoon < 101.25
            ? "E"
            : compassMoon < 123.75
            ? "ESE"
            : compassMoon < 146.25
            ? "SE"
            : compassMoon < 168.75
            ? "SSE"
            : compassMoon < 191.25
            ? "S"
            : compassMoon < 213.75
            ? "SSO"
            : compassMoon < 236.25
            ? "SO"
            : compassMoon < 258.75
            ? "OSO"
            : compassMoon < 281.25
            ? "O"
            : compassMoon < 303.75
            ? "ONO"
            : compassMoon < 326.25
            ? "NO"
            : compassMoon < 348.75
            ? "NNO"
            : "N"
          : this.showHour(this.maintenant());

      return result;
    },

    nowYposition() {
      let d = this.altiLine(this.maintenant());
      // let d = this.altiLine(this.maintenant);
      if (d > 15) return this.yMoon(d) + this.spaceAround.top - 10;
      if (d >= -0.1) return this.yMoon(15) + this.spaceAround.top - 10;
      return this.spaceAround.top + this.chartHeight / 2 + 10;
      // yMoon(altiLine(this.maintenant)) +
      //                 spaceAround.top-10
      // let d = this.altiLine(this.maintenant);
      // if (d > 40 && d < 55) {
      //   let result = this.spaceAround.top + this.chartHeight / 2 - 10;
      //   return result;
      // } else {
      //   return this.spaceAround.top + this.chartHeight / 2;
      // }
    },

    //POSITION OF LEGEND OF GOLD AND BLUE
    textColorYposition(d) {
      if (this.altiLine(d.start) > 45) {
        return this.spaceAround.top + this.chartHeight - 50;
      } else {
        return this.spaceAround.top + 20;
      }
    },

    tickPath(d, type) {
      if (type === "sun") {
        return `M${this.x(d.start)} ${this.spaceAround.top - 4}  V ${this
          .spaceAround.top + this.chartHeight}`;
      } else if (type === "moon") {
        return `M${this.x(d.time)} ${this.spaceAround.top +
          this.chartHeight -
          8}  V ${this.spaceAround.top + this.chartHeight}`;
      }
    },
    generateAxis() {
      var xAxisQuart = d3.select("#xAxisQuart");
      var xAxisHour = d3.select("#xAxisHour");
      const axisQuartdHeure = d3
        .axisBottom(this.x)
        .ticks(d3.timeMinute.every(10), "");
      const axisHour = d3
        .axisBottom(this.x)
        .ticks(d3.timeMinute.every(60), "%H:%M")
        .tickSize(11);
      xAxisQuart
        .call(axisQuartdHeure)
        .select(".domain")
        .remove();
      xAxisHour
        .call(axisHour)
        .selectAll(".tick text")
        .attr("font-size", "1.1em")
        .attr("font-weight", "500");

      xAxisHour.select(".domain").remove();
    },
    generateYaxis() {
      var yAxis = d3.select("#yMoonAxis");
      yAxis.call(
        d3.axisLeft(this.yMoon).tickFormat((x) => (x % 30 === 0 ? x : ""))
      );
    },

    showHour(d) {
      return d
        .toLocaleString(undefined, { hour: "numeric", minute: "numeric" })
        .toUpperCase();
    },

    // CHANGE TITLE
    onScroll(e) {
      if (e.target.scrollLeft > this.x(this.tomorrow)) this.isItTomorrow = true;
      else this.isItTomorrow = false;
    },

    //SCROLL TO FUNCTION
    scrollTo(time) {
      let timeLine = document.getElementById("timeLine");

      //stop rAF from drag momentum
      this.cancelMomentumTracking();
      //stop touch scroll momentum
      timeLine.style.overflow = "hidden";
      setTimeout(function() {
        timeLine.style.overflow = "";
      }, 10);

      //this is the function
      timeLine.scrollTo(this.x(d3.timeMinute.offset(time, -30)), 0);
    },

    //DRAG FUNCTIONS
    dragstart(e) {
      const slider = document.getElementById("timeLine");
      this.isDown = true;
      slider.classList.add("active");
      this.startX = e.pageX - slider.offsetLeft;
      this.scrollLeft = slider.scrollLeft;
      this.cancelMomentumTracking();
    },
    dragend() {
      const slider = document.getElementById("timeLine");
      this.isDown = false;
      slider.classList.remove("active");
      this.beginMomentumTracking();
    },

    dragleave() {
      const slider = document.getElementById("timeLine");
      this.isDown = false;
      slider.classList.remove("active");
    },
    drag(e) {
      if (!this.isDown) return;
      // console.log(e.pageX)
      const slider = document.getElementById("timeLine");
      e.preventDefault();
      const x = e.clientX - slider.offsetLeft;
      const walk = (x - this.startX) * 1.5; //scroll-fast
      var prevScrollLeft = slider.scrollLeft;
      slider.scrollLeft = this.scrollLeft - walk;
      this.velX = slider.scrollLeft - prevScrollLeft;
    },

    // Momentum for drag
    beginMomentumTracking() {
      this.cancelMomentumTracking();
      this.momentumID = requestAnimationFrame(this.momentumLoop);
    },
    cancelMomentumTracking() {
      cancelAnimationFrame(this.momentumID);
    },
    momentumLoop() {
      const slider = document.getElementById("timeLine");
      slider.scrollLeft += this.velX;
      this.velX *= 0.95;
      if (Math.abs(this.velX) > 0.5) {
        this.momentumID = requestAnimationFrame(this.momentumLoop);
      }
    },

    // STORE COMMIT
    goNow() {
      console.log(this.maintenant());
      if (this.maintenant().getHours() < 4) {
        this.$store.commit("JOUR_SET", this.maintenant());
        this.$store.commit("JOUR_PRECEDENT", 1);
        this.scrollTo(this.maintenant());
      } else {
        this.$store.commit("JOUR_SET", this.maintenant());
        this.scrollTo(this.maintenant());
      }
    },
    nextDay(n) {
      this.$store.commit("JOUR_SUIVANT", n);
    },
    prevDay(n) {
      this.$store.commit("JOUR_PRECEDENT", n);
    },
  },

  ///////////////////////////// COMPUTED ////////////////////////////////////
  ///////////////////////////// COMPUTED ////////////////////////////////////
  ///////////////////////////// COMPUTED ////////////////////////////////////
  ///////////////////////////// COMPUTED ////////////////////////////////////

  computed: {
    chartHeight() {
      return this.daheight < 550 ? 110 : 150;
    },
    //STORE ACCESS
    date() {
      return d3.timeDay(this.$store.state.date);
    },
    tz() {
      return this.$store.getters.tz;
    },
    latitude() {
      return this.$store.getters.lat;
    },
    longitude() {
      return this.$store.getters.long;
    },

    showAltitude() {
      return this.$store.state.options.one.showAltitude;
    },

    title() {
      if (this.isItTomorrow) {
        return this.tomorrow
          .toLocaleString(undefined, {
            weekday: "long",
            day: "numeric",
            month: "long",
          })
          .toUpperCase();
      } else {
        return this.date
          .toLocaleString(undefined, {
            weekday: "long",
            day: "numeric",
            month: "long",
          })
          .toUpperCase();
      }
    },

    tomorrow() {
      return d3.timeHour.offset(this.date, 24);
    },

    // isItToday() {
    //   return (
    //     new Date().getDate() === this.date.getDate() &&
    //     new Date().getFullYear() === this.date.getFullYear() &&
    //     new Date().getMonth() === this.date.getMonth()
    //   );
    // },

    timeToScrollTo() {
      var gold = this.SunDATA.find((obj) => obj.id === "goldenHour").start;
      var dawns = [];
      for (let i = 0; i < this.SunDATA.length; i++)
        if (this.SunDATA[i].id === "dawn") dawns.push(this.SunDATA[i].start);

      return {
        gold: gold,
        dawn: dawns[0],
        nextDawn: dawns[1],
      };
    },

    chartContainerHeightpx() {
      return (
        this.chartHeight + this.spaceAround.top + this.spaceAround.bottom + "px"
      );
    },
    colorHeight() {
      return this.chartHeight + "px";
    },
    axisXposition() {
      return `translate(0,${this.chartHeight +
        this.spaceAround.top +
        this.spaceAround.bottom / 10})`;
    },
    axisYposition() {
      return `translate(23,${this.spaceAround.top})`;
    },

    moonLineYposition() {
      return `translate(0,${this.spaceAround.top})`;
    },

    overwidth() {
      //numbers of hours to show * number of pixel for 1 hour
      return 36 * 150;
    },

    domainInOverwidth() {
      return [
        d3.timeHour.offset(this.date, 0),
        d3.timeHour.offset(this.date, 36),
      ];
    },

    midiMinuit() {
      var midi = d3.timeHour.offset(this.date, 12);
      var minuit = d3.timeHour.offset(this.date, 24);
      var x = this.x;
      var y0 = this.spaceAround.top;
      var y1 = y0 + this.chartHeight;
      var result = [
        { id: "12:00", color: "#FFF", time: midi, text: "12:00" },
        {
          id: "00:00",
          color: "#FFF",
          time: minuit,
          text:
            "00:00 ( " +
            this.domainInOverwidth[1]
              .toLocaleString(undefined, {
                weekday: "long",
                day: "numeric",
                month: "long",
              })
              .toUpperCase() +
            " )",
        },
      ];

      result.forEach(function(d) {
        d.key = result.indexOf(d);
        d.path = `M ${x(d.time)} ${y0} V ${y1}`;
      });

      return result;
    },

    ///////////////////////////////////////////////////////////////
    ////  SUN DATA  ///////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////

    SunDATA() {
      const x = this.x;
      const y = this.chartHeight / 2 + this.spaceAround.top;
      const days = [
        d3.timeHour.offset(this.date, 12),
        d3.timeHour.offset(this.date, 36),
      ];
      //define times of the day to use
      const color = this.color;
      const sunTimes = [
        "nightEnd",
        "dawn",
        "blueHourEnd",
        "sunrise",
        "goldenHourEnd",
        "goldenHour",
        "sunset",
        "blueHourStart",
        "dusk",
        "night",
      ];

      //get those times for all days (for loop might be useless if only two days)
      var tempResult = [];
      for (let i = 0; i < days.length; i++) {
        var sun = suncalc.getTimes(days[i], this.latitude, this.longitude);
        tempResult = tempResult.concat(
          [...sunTimes].map((d) => ({ id: d, start: this.convertTZ(sun[d]) })) // this is where we get times for one day
        );
      }
      var result = [];
      let i = 0;
      // Le premier :
      if (!isNaN(tempResult[i].start)) {
        result.push({
          id: "night",
          start: d3.timeDay(this.date),
          end: tempResult[i].start,
          key: 0,
        });
      } else {
        i += 1;
        while (isNaN(tempResult[i].start) && i < tempResult.length) {
          i += 1;
        }
        result.push({
          id: tempResult[i - 1].id,
          start: d3.timeDay(this.date),
          end: tempResult[i].start,
          key: i + 1,
        });
      }

      // Les autres :
      for (i; i < tempResult.length - 2; i++) {
        let iTemp = i;
        while (isNaN(tempResult[i + 1].start) && i < tempResult.length - 2) {
          i += 1;
        }
        result.push({
          id: tempResult[iTemp].id,
          start: tempResult[iTemp].start,
          end: tempResult[i + 1].start,
          key: i + 2,
        });
        if (isNaN(tempResult[i + 1].start)) {
          i += 1;
        }
      }

      // //adding the first night manualy
      // result.push({
      //   id: "firstNight",
      //   start: d3.timeDay(this.date),
      //   end: isNaN(tempResult[0].start) ? d3.timeDay(this.date) : tempResult[0].start,
      //   key: 0,
      // });

      // // here we add the endTime of every suntimes (except the last one)
      // for (let i = 0; i < tempResult.length - 2; i++) {
      //   if (isNaN(tempResult[i + 1].start)) {
      //     var iTemp = i;
      //     i += 1;
      //     while (isNaN(tempResult[i].start)) {
      //       i += 1;
      //     }
      //     result.push(
      //       {
      //         id: tempResult[iTemp].id,
      //         start: tempResult[iTemp].start,
      //         end: tempResult[i].start,
      //         key: i,
      //       },
      //       {
      //         id: tempResult[i].id,
      //         start: tempResult[i].start,
      //         end: tempResult[i + 1].start,
      //         key: i + 1,
      //       }
      //     );
      //   } else {
      //     result.push({
      //       id: tempResult[i].id,
      //       start: tempResult[i].start,
      //       end: tempResult[i + 1].start,
      //       key: i + 1,
      //     });
      //   }
      // }

      // add color and color name
      for (let i = 0; i < result.length; i++) {
        switch (result[i].id) {
          case "nightEnd":
          case "dusk":
            result[i].color = color.astro;
            break;
          case "dawn":
          case "blueHourStart":
            result[i].color = color.blue;
            result[i].photo = "Blue";
            break;
          case "blueHourEnd":
          case "sunset":
            result[i].color = color.dusk;
            break;
          case "sunrise":
          case "goldenHour":
            result[i].color = color.gold;
            result[i].photo = "Gold";
            break;
          case "goldenHourEnd":
            result[i].color = color.day;
            break;
          case "night":
            if (
              i > 1 &&
              i < result.length - 1 &&
              result[i + 1].id != "nightEnd"
            ) {
              result[i].color = color.astro;
            } else {
              result[i].color = color.night;
            }
            break;
          default:
            result[i].color = color.night;
        }
      }
      //   result = result.filter(d=>
      //                      d.end >= d3.timeHour.offset(date,startHour) &&
      //                      d.start <= d3.timeHour.offset(date,startHour+numberOfHoursToShow))

      //  finally we filter the result to get only the sunTimes we want to put on the chart
      //ADDING THE PATH
      result.forEach(function(d) {
        d.path = `M ${x(d.start)} ${y} H ${x(d.end) + 1}`;
      });
      // console.table(tempResult);
      return result;
    },

    sunriseEtSunset() {
      return [
        ...this.SunDATA.filter((d) => d.id == "sunset" || d.id == "sunrise"),
      ];
    },

    photoData() {
      return [...this.SunDATA.filter((d) => d.photo != null)];
    },

    ///////////////////////////////////////////////////////////////
    ////  MOON DATA  //////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////

    MoonDATA() {
      var points = d3.timeHour.range(
        d3.timeHour.offset(this.date, -12),
        d3.timeHour.offset(this.convertTZ(this.date), 37)
      );
      return [...points].map((d) => ({
        x: this.convertTZ(d),
        y:
          (suncalc.getMoonPosition(d, this.latitude, this.longitude).altitude *
            180) /
          Math.PI,
      }));
    },

    moonTimes() {
      const days = [
        d3.timeHour.offset(this.date, -24),
        d3.timeHour.offset(this.date, 0),
        d3.timeHour.offset(this.date, 24),
      ];
      let list = [];
      for (let i = 0; i < days.length; i++) {
        var moon = suncalc.getMoonTimes(
          this.convertTZ(days[i]),
          this.latitude,
          this.longitude
        );
        if (moon.rise != undefined) {
          list.push({ id: "rise", time: this.convertTZ(moon.rise) });
        }
        if (moon.set != undefined) {
          list.push({ id: "set", time: this.convertTZ(moon.set) });
        }
      }

      return list;
    },

    ///////////////////////////////////////////////////////////////
    ////  MOON LINE  //////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////

    moonLine() {
      var path = d3
        .line()
        .curve(d3.curveCardinal)
        .x((d) => this.xMoon(d.x))
        .y((d) => this.yMoon(d.y));
      return path(this.MoonDATA);
    },

    ///////////////////////////////////////////////////////////////
    ////  MOON PHASE  /////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////

    moonPhase() {
      const angle =
        180 - suncalc.getMoonIllumination(new Date(this.date)).phase * 360;
      const projection = d3
        .geoOrthographic()
        .translate([0, 0])
        .scale(20)
        .rotate([angle, 0]);
      const hemisphere = d3.geoCircle()();
      return projection, d3.geoPath(projection)(hemisphere);
    },

    ///////////////////////////////////////////////////////////////
    ////  LEGEND  /////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////

    ///////////////////////////////////////////////////////////////
    ////  SCALES  /////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////

    x() {
      return d3
        .scaleTime()
        .domain(this.domainInOverwidth)
        .range([0, this.overwidth]);
    },

    xMoon() {
      return d3
        .scaleTime()
        .domain(this.domainInOverwidth)
        .range([0, this.overwidth]);
    },

    yMoon() {
      return d3
        .scaleLinear()
        .domain([90, 0])
        .range([0, this.chartHeight - 1]);
    },
  },
};
</script>

<style scoped>
* {
  scrollbar-width: none; /* Firefox hide scrollbar*/
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
h3 {
  font-family: "Roboto Condensed", sans-serif;
}

h3:hover {
  color: black;
  cursor: pointer;
}

.lock-screen {
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: fixed;
  padding-bottom: 112px;
}

.content {
  height: 100%;
  padding: 16px;
  max-width: 800px;
  margin: auto;
}

.parent {
  margin-top: 3vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}

.head {
  margin-bottom: 0px;
  height: auto;
}

.svg-moon {
  max-height: 80px;
}

.contour-moon {
  fill: #383839;
  stroke: #383839;
  stroke-width: 2px;
}

.forme-moon {
  fill: #ffffff;
}

.chart-container {
  /* border: 1px solid rgb(117, 17, 248); */
  width: 100%;
  /* height: 200px; */
  display: inline-flex;
  margin-bottom: 20px;
}

.y-axis {
  position: relative;
  width: 30px;
  margin-right: -5px;
  z-index: 5;
}

.scroll-container {
  width: 100%;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  /* scroll-behavior: smooth; */
  cursor: grab;
  cursor: -webkit-grab;
}

.scroll-container.active {
  cursor: grabbing;
  /* scroll-behavior: unset; */
  cursor: -webkit-grabbing;
}

.scroll-container::-webkit-scrollbar {
  /* Scrollbars */
  /* width: 2px; */
  /* height: 0.1em;
  background: transparent; */

  display: none;
}

.svgoverflow {
  /* background-color: lightblue; */
  height: 100%;
  display: block;
}

.nav-container {
  width: 100%;
}

.nav {
  /* border: 1px solid rgb(117, 17, 248); */
  height: auto;
  max-width: 500px;
  margin: auto;
}

.moon-line {
  fill: none;
  stroke: white;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.legend {
  /* fill: red; */
  text-anchor: middle;
  font-family: "Roboto Condensed", "Arial", sans-serif;
  font-weight: 700;
  font-size: 0.9em;
  /* -webkit-font-smoothing: subpixel-antialiased; */
}

.tick-info {
  text-anchor: start;
  font-family: "Roboto Condensed", "Arial", sans-serif;
  font-weight: 600;
  font-size: 0.9em;
  fill: white;
}

.legend-tick {
  stroke-width: 1;
  /* stroke-linecap: round; */
}
/* 
.color-path{
  stroke: blue;
} */
</style>
