import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  created: function () {
    store.subscribe((mutation, state) => {

      let cities = {
        cities: state.cities,
        cityPick: state.cityPick,
      };

      let options = {
        options: state.options,
      }
    
      // Store the state object as a JSON string
      localStorage.setItem('cities', JSON.stringify(cities));
      localStorage.setItem('options', JSON.stringify(options));
    })
  },
	beforeCreate() {
		this.$store.commit('initialiseStore');
	},
  store,
  render: h => h(App)
}).$mount('#app')
