import { render, staticRenderFns } from "./nuitCalItem.vue?vue&type=template&id=b3873442&scoped=true&"
import script from "./nuitCalItem.vue?vue&type=script&scoped=true&lang=js&"
export * from "./nuitCalItem.vue?vue&type=script&scoped=true&lang=js&"
import style0 from "./nuitCalItem.vue?vue&type=style&index=0&id=b3873442&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3873442",
  null
  
)

export default component.exports

/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Intersect from 'vuetify/lib/directives/intersect'
installDirectives(component, {Intersect})
