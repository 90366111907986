<template>
  <div>
    <svg
      class="svg"
      :viewBox="viewBox"
      :height="dayHeight"
      v-intersect="{
        handler: onIntersect,
        options: rootOptions,
      }"
    >
      <g>
        <!-- ANY JOUR -->
        <rect
          v-if="!isItToday()"
          class="fullRect"
          :rx="roundCorner"
          :fill="$vuetify.theme.dark ? color.day.dark : color.day.light"
        />

        <!-- JOUR TODAY -->
        <rect
          v-if="isItToday()"
          class="fullRect"
          :rx="roundCorner"
          :fill="
            $vuetify.theme.dark
              ? color.day.aujourdhui.dark
              : color.day.aujourdhui.light
          "
        />
      </g>

      <g>
        <!-- NIGHT TODAY -->
        <path
          v-if="isItToday()"
          :stroke-width="dayHeight"
          :d="nightLine"
          :stroke="
            $vuetify.theme.dark
              ? color.night.aujourdhui.dark
              : color.night.aujourdhui.light
          "
        />

        <!-- ANY NIGHT -->
        <path
          v-if="!isItToday()"
          :stroke-width="dayHeight"
          :d="nightLine"
          :stroke="$vuetify.theme.dark ? color.night.dark : color.night.light"
        />
      </g>

      <!-- MOON PATH -->
      <g>
        <path class="moon" :stroke-width="dayHeight / 2" :d="moonLine" />
      </g>

      <g v-if="date.getDate() != 1">
        <!-- Si c'est le premier du mois, on affiche le nom du mois à la place -->

        <!-- ANY MOON -->
        <circle
          v-if="!isItToday()"
          class="moonCircle"
          :r="moonSize"
          :cx="width * 0.5"
          :cy="dayHeight / 2"
          stroke="#FFF"
          :fill="$vuetify.theme.dark ? color.night.dark : color.night.light"
        />

        <!-- MOON TODAY -->
        <circle
          v-if="isItToday()"
          class="moonCircle"
          :r="moonSize"
          :cx="width * 0.5"
          :cy="dayHeight / 2"
          stroke="#FFF"
          :fill="
            $vuetify.theme.dark
              ? color.night.aujourdhui.dark
              : color.night.aujourdhui.light
          "
        />

        <!-- ALL MOON SHAPES ARE WHITE -->
        <path class="moonShape" :d="moonPhase" />
      </g>
      <!-- fin du v-if 1er du mois -->

      <!-- TEXT JOUR (tous les jours (weekend en gras)) -->
      <text
        class="dateTexte"
        x="3%"
        y="50%"
        :font-weight="date.getDay() == 0 || date.getDay() == 6 ? 800 : 300"
      >
        {{
          date.toLocaleString(undefined, {
            weekday: "short",
            day: "numeric",
          })
        }}
      </text>

      <!-- TEXT MOIS (le 1er de chaque mois) -->
      <text v-if="date.getDate() === 1" class="monthTexte" x="50%" y="50%">
        {{
          date
            .toLocaleString(undefined, {
              month: "long",
            })
            .toUpperCase()
        }}
      </text>

      <!-- NOW tick rouge -->
      <g>
        <path v-if="isItToday()" stroke="red" :d="nowRedLine" />
      </g>

      <!-- STORE DATE  HIGHTLIGHT (contour noir)/> -->
      <g>
        <rect
          v-if="isItTheStoreDay()"
          class="storeRect"
          :rx="roundCorner"
          :width="width - 1"
          :stroke="$vuetify.theme.dark ? 'white' : 'black'"
        />
      </g>
    </svg>
  </div>
</template>

<script scoped>
import * as d3 from "d3";
var SunCalc = require("suncalc");

export default {
  name: "nuitCalItem",
  props: {
    date: {
      required: true,
      type: Date,
    },
    width: {
      default: window.innerWidth,
      type: Number,
    },
    dayHeight: {
      default: 35,
      type: Number,
    },
    latitude: {
      default: 48.86,
      type: Number,
    },
    longitude: {
      default: 2.33,
      type: Number,
    },
  },
  data() {
    return {
      padding: 20,
      theMonth: this.date
        .toLocaleString(undefined, {
          // weekday: "long",
          // day: "numeric",
          month: "long",
        })
        .toUpperCase(),
      // previousY: 0,
      // previousRatio: 0,
      rootHeight: window.innerHeight,
      today: new Date(),
      roundCorner: 5,
      // color: {
      //   day: {
      //     light: "#B2C9D9",
      //     dark: "#777",
      //     aujourdhui: { light: "#FFCB7D", dark: "#BBB" },
      //   },
      //   night: {
      //     light: "#52809E",
      //     dark: "#333333",
      //     aujourdhui: { light: "#333333", dark: "#555" },
      //   },
      // },
      color: {
        day: {
          // light: "#B2C9D9",
          light: "#B2C9D9",
          dark: "#777",
          aujourdhui: { light: "#FFCB7D", dark: "#FFCB7D" },
          // aujourdhui: { light: "#FFCB7D", dark: "#BBB" },
        },
        night: {
          // light: "#52809E",
          light: "#495359",
          dark: "#333333",
          aujourdhui: { light: "#333333", dark: "#151515" },
          // aujourdhui: { light: "#333333", dark: "#555" },
        },
      },
    };
  },

  methods: {
    isItToday() {
      return (
        this.date.getDate() === this.today.getDate() &&
        this.date.getFullYear() === this.today.getFullYear() &&
        this.date.getMonth() === this.today.getMonth()
      );
    },

    isItTheStoreDay() {
      return (
        this.date.getDate() === this.storeDate.getDate() &&
        this.date.getFullYear() === this.storeDate.getFullYear() &&
        this.date.getMonth() === this.storeDate.getMonth()
      );
    },

    convertTZ(uneDate) {
      if (uneDate === undefined) {
        return undefined;
      }
      return new Date(uneDate.toLocaleString("en-US", { timeZone: this.tz }));
    },

    onIntersect(entries) {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return;
        }
        this.$emit("sendMonth", this.date);
      });
    },
  },

  computed: {
    storeDate() {
      return d3.timeDay(this.$store.state.date);
    },
    tz() {
      return this.$store.getters.tz;
    },

    rootOptions() {
      return { rootMargin: `-116px 0px ${-this.rootHeight + 116}px 0px` };
    },
    // {
    //   handler: onIntersect,
    //   options: {rootOptions}
    // }

    viewBox() {
      return `0 0 ${this.width} ${this.dayHeight}`;
    },

    moonSize() {
      return this.dayHeight * 0.35;
    },

    x() {
      return d3
        .scaleLinear()
        .domain([0, 24 * 60 * 60 * 1000])
        .range([0, this.width]);
    },

    itemData() {
      var noon = d3.timeHour(
        new Date(
          this.date.getFullYear(),
          this.date.getMonth(),
          this.date.getDate(),
          12,
          0,
          0,
          0
        )
      );
      var sun = SunCalc.getTimes(noon, this.latitude, this.longitude);
      var moon = SunCalc.getMoonTimes(noon, this.latitude, this.longitude);
      var noonJourSuivant = d3.timeHour.offset(noon, 24);
      var sunJourSuivant = SunCalc.getTimes(
        noonJourSuivant,
        this.latitude,
        this.longitude
      );
      var moonJourSuivant = SunCalc.getMoonTimes(
        noonJourSuivant,
        this.latitude,
        this.longitude
      );
      return {
        day: noon,
        sunrise: this.convertTZ(sunJourSuivant.sunriseEnd),
        sunset: this.convertTZ(sun.sunsetStart),
        moonrise:
          noon <= this.convertTZ(moon.rise)
            ? this.convertTZ(moon.rise)
            : this.convertTZ(moonJourSuivant.rise) <=
              this.convertTZ(noonJourSuivant)
            ? this.convertTZ(moonJourSuivant.rise)
            : undefined,
        moonset:
          noon <= this.convertTZ(moon.set)
            ? this.convertTZ(moon.set)
            : moonJourSuivant.set <= noonJourSuivant
            ? this.convertTZ(moonJourSuivant.set)
            : undefined,
        moonstate: moon.alwaysDown ? -1 : moon.alwaysUp ? 1 : 0,
        moonphase: SunCalc.getMoonIllumination(noon).phase,
      };
    },

    nightLine() {
      var x = this.x;
      var y = this.dayHeight / 2;
      var start = this.itemData.sunset - this.itemData.day;
      var end = this.itemData.sunrise - this.itemData.day;
      if (isNaN(start) && isNaN(end)) {
        var posAtNoon = SunCalc.getPosition(
          this.itemData.day,
          this.latitude,
          this.longitude
        ).altitude;
        return posAtNoon > 0 ? undefined : `M ${x(0)} ${y} H ${x(864e5)} `;
      }
      return isNaN(start)
        ? x(end) < x(576e5) ? undefined : `M ${x(0)} ${y} H ${x(end)}`
        : isNaN(end)
        ? x(start) > x(288e5) ? undefined : `M ${x(start)} ${y} H ${x(864e5)}`
        : `M ${x(start)} ${y} H ${x(end)}`;
    },

    moonLine() {
      var x = this.x;
      var y = this.dayHeight / 2;
      var d = this.itemData;
      var rise = d.moonrise - d.day;
      var set = d.moonset - d.day;

      if(d.moonstate != 0 || (isNaN(d.moonrise) && isNaN(d.moonset))){
        var posAtNoon = SunCalc.getMoonPosition(
          d.day,
          this.latitude,
          this.longitude
        ).altitude;
        return posAtNoon < 0 ? undefined : `M ${x(0)} ${y} H ${x(864e5)} `; 

      } else {

      return d.moonrise <= d.moonset
        ? `M ${x(rise)} ${y} H ${x(set)}`
        : isNaN(d.moonrise)
        ? `M ${x(0)} ${y} H ${x(set)}`
        : isNaN(d.moonset)
        ? `M ${x(rise)} ${y} H ${x(864e5)}`
        : `M ${x(0)} ${y} H ${x(set)} M ${x(rise)} ${y} H ${x(864e5)}`;
        }
    },

    moonPhase() {
      const angle = 180 - this.itemData.moonphase * 360;
      const projection = d3
        .geoOrthographic()
        .translate([this.width * 0.5, this.dayHeight / 2])
        .scale(this.moonSize)
        .rotate([angle, 0]);
      const hemisphere = d3.geoCircle()();

      return projection, d3.geoPath(projection)(hemisphere);
    },

    nowRedLine() {
      let now = this.convertTZ(new Date());
      let simpleNow =
        ((now.getHours() - 12) * 60 + now.getMinutes()) * 60 * 1000;
      return `M ${this.x(simpleNow)} 0 V ${this.dayHeight}`;
    },
  },
};
</script>

<style lang="sass" scoped>
*
  scrollbar-width: none
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select:none
  user-select: none

.fullRect
  margin: 0
  height: 100%
  width: 100%

.storeRect
  height: 100%
  fill: none
  stroke-width: 1px
  transform: translate(.5px,0)

.svg
  width: 100%
  padding: 0px 16px
  margin: 0px
  cursor: pointer

.dateTexte
  fill: #000
  alignment-baseline: middle
  font-size: small

.monthTexte
  fill: #FFF
  alignment-baseline: middle
  font-size: medium
  text-anchor: middle

.moon
  stroke: #FFF
  opacity: .5

.moonShape
  fill: #FFF

.moonCircle
  stroke-width: 1px
</style>
