import Vue from 'vue'
import VueRouter from 'vue-router'
import nuitCal from '../views/nuitCal.vue'
import nuitOne from '../views/nuitOne.vue'
import cities from '../views/cities.vue'
import canvascompass from '../views/canvascompass.vue'
import pageNotFound from '../views/pageNotFound.vue'
import infos from '../views/infos.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/calendar',
    name: 'Calendar',
    component: nuitCal
  },
  {
    path: '/',
    name: 'Today',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: nuitOne
  },
  {
    path: '/cities',
    name: 'Cities',
    component: cities
  },
  {
    path: '/compass',
    name: 'Compass',
    component: canvascompass
  },
  {
    path: '/infos',
    name: 'Infos',
    component: infos
  },
  { path: '/404', component: pageNotFound },
  { path: '*', component: pageNotFound },  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
