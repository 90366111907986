<template>
  <div class="lock-screen">
    <div class="content" style="max-width: 900px">
      <!-- :style="ctxHeight < 600 ? 'max-width: 780px' : 'max-width: 1000px'" -->
      <div class="parent" v-resize="onResize">
        <div class="two-canvas-container">
          <canvas id="background-canvas"></canvas>
          <canvas
            id="projection-canvas"
            @touchstart="touchstart"
            @touchmove="touchmove"
            @touchend="touchend"
            @mousedown="dragstart"
            @mousemove="drag"
            @mouseup="dragend"
          >
          </canvas>

          <!-- ZOOOOOOM -->
          <v-btn
            @click="zoom"
            fab
            outlined
            small
            :style="{
              backgroundColor: colors.fond,
              marginLeft: ctxWidth - 48 + 'px',
              marginTop: 8 + 'px',
            }"
          >
            <v-icon>{{
              zoomState
                ? "mdi-magnify-minus-outline"
                : "mdi-magnify-plus-outline"
            }}</v-icon>
          </v-btn>

          <!-- DEVICE ORIENTATIOOOOOOOON -->
          <!-- v-if="isIOS && !deviceOrientationActive" -->
          <v-btn
            v-if="isIOS"
            @click="startDeviceOrientation"
            color="error"
            fab
            outlined
            small
            :style="{
              backgroundColor: colors.fond,
              marginLeft: ctxWidth - 48 + 'px',
              marginTop: 8 + 'px',
            }"
          >
            <v-icon>
              {{
                deviceOrientationActive
                  ? "mdi-navigation"
                  : "mdi-navigation-outline"
              }}
            </v-icon>
          </v-btn>

          <!-- Turn Around BUTTON -->
          <!-- v-if="isIOS && !deviceOrientationActive" -->
          <v-btn
            v-if="turnAroundActive"
            @click="turnAround"
            color="red"
            fab
            outlined
            small
            class="turnAroundButtun"
            :style="{
              marginTop: (ctxHeight * 1) / 2 + 'px',
            }"
          >
            <v-icon> mdi-rotate-3d-variant </v-icon>
          </v-btn>
        </div>
        <div class="bottom-nav">
          <timeWheel
            :height="50"
            :width="ctxWidth"
            :maxWidth="ctxHeight < 600 ? 780 : 1000"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import timeWheel from "@/components/timeWheel.vue";

import * as d3 from "d3";
const suncalc = require("suncalc");

const ephemeris = require("ephemeris");

import stars from "@/data/stars.json";
import constelLines from "@/data/rankyconstellationlines.json";
import constelLabel from "@/data/constellationlabels.json";
import horizontalGrat from "@/data/horizontalGrat.json";
import verticalGrat from "@/data/verticalGrat.json";

// import {mapState} from 'vuex'

export default {
  name: "canvasCompass",

  components: {
    timeWheel,
  },

  //
  //         ,,
  //       `7MM             mm
  //         MM             MM
  //    ,M""bMM   ,6"Yb.  mmMMmm   ,6"Yb.
  //  ,AP    MM  8)   MM    MM    8)   MM
  //  8MI    MM   ,pm9MM    MM     ,pm9MM
  //  `Mb    MM  8M   MM    MM    8M   MM
  //   `Wbmd"MML.`Moo9^Yo.  `Mbmo `Moo9^Yo.
  //
  //

  data: () => ({
    // mobileDevice: false,
    pxRatio: 1,

    deviceOrientationActive: false,
    compassOrientation: 1,
    turnAroundActive: false,
    turnToObjectif: null,
    turnTransition: "",
    turnRight: false,

    isIOS:
      navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
      navigator.userAgent.match(/AppleWebKit/),

    // CANVAS
    canvas: null,
    context: null,

    // BACKGROUND CANVAS
    backCanvas: null,
    backContext: null,

    ctxWidth: 300,
    ctxHeight: 400,

    // VARIABLE POUR LA PROJECTION
    projectionScale: 300,
    rot1: -20,
    rot2: -175,
    equator: {
      type: "LineString",
      coordinates: [
        [-180, 0],
        [-90, 0],
        [0, 0],
        [90, 0],
        [180, 0],
      ],
    },

    //DATA VARIABLE
    // magMax: 5,

    // DRAG VARIABLE
    isDragging: false,
    x0: 0,
    velX: 0,
    momentumID: "",
    throttleTouch: false,
    spinning: false,
    scrollTimer: null,

    //ZOOM
    zoomState: false,
  }),

  //
  //                                                ,,
  //                               mm             `7MM
  //                               MM               MM
  //  `7M'    ,A    `MF' ,6"Yb.  mmMMmm   ,p6"bo    MMpMMMb.
  //    VA   ,VAA   ,V  8)   MM    MM    6M'  OO    MM    MM
  //     VA ,V  VA ,V    ,pm9MM    MM    8M         MM    MM
  //      VVV    VVV    8M   MM    MM    YM.    ,   MM    MM
  //       W      W     `Moo9^Yo.  `Mbmo  YMbmd'  .JMML  JMML.
  //
  //

  watch: {
    // offsetDate: function() {
    //   this.drawCompass(true);
    // },
    rot1: function() {
      if (this.rot1 > 180) {
        this.rot1 -= 360;
      }
      if (this.rot1 < -180) {
        this.rot1 += 360;
      }
      this.isTurnAroundActive();
    },
    compassOrientation: function() {
      this.isTurnAroundActive();
    },
    minuteToOffset: function() {
      this.drawCompass(true);
    },
    startDate: function() {
      this.drawCompass(false);
    },
    latitude: function() {
      this.drawCompass();
    },
    longitude: function() {
      this.drawCompass();
    },
    isitdark: function() {
      this.drawBackground();
      this.drawCompass();
    },
    optionWatcher: function() {
      this.drawBackground();
      this.drawCompass();
    },
    // landScapeMode: function() {
    //   this.goLandscape();
    //   this.drawCompass();
    // },
  },

  //
  //                                                                              ,,
  //                                                        mm                  `7MM
  //                                                        MM                    MM
  //  `7MMpMMMb.pMMMb.   ,pW"Wq.  `7MM  `7MM  `7MMpMMMb.  mmMMmm   .gP"Ya    ,M""bMM
  //    MM    MM    MM  6W'   `Wb   MM    MM    MM    MM    MM    ,M'   Yb ,AP    MM
  //    MM    MM    MM  8M     M8   MM    MM    MM    MM    MM    8M"""""" 8MI    MM
  //    MM    MM    MM  YA.   ,A9   MM    MM    MM    MM    MM    YM.    , `Mb    MM
  //  .JMML  JMML  JMML. `Ybmd9'    `Mbod"YML..JMML  JMML.  `Mbmo  `Mbmmd'  `Wbmd"MML.
  //
  //

  mounted: function() {
    // if (
    //   /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    //     navigator.userAgent
    //   )
    // ) {
    //   this.mobileDevice = true;
    // }

    // Get the size of the canvas in CSS pixels.
    this.pxRatio = window.devicePixelRatio || 1;

    // if (this.landScapeMode) {
    //   this.goLandscape();
    // }

    this.resizeCanvas();

    this.context = this.canvas.getContext("2d");
    this.backContext = this.backCanvas.getContext("2d");
    this.context.scale(this.pxRatio, this.pxRatio);
    this.backContext.scale(this.pxRatio, this.pxRatio);

    this.drawBackground();
    this.isTurnAroundActive();
    this.drawCompass(false);
  },

  //
  //                                                                                         ,,
  //    .g8"""bgd                                                      mm                  `7MM
  //  .dP'     `M                                                      MM                    MM
  //  dM'       `  ,pW"Wq.  `7MMpMMMb.pMMMb.  `7MMpdMAo. `7MM  `7MM  mmMMmm   .gP"Ya    ,M""bMM
  //  MM          6W'   `Wb   MM    MM    MM    MM   `Wb   MM    MM    MM    ,M'   Yb ,AP    MM
  //  MM.         8M     M8   MM    MM    MM    MM    M8   MM    MM    MM    8M"""""" 8MI    MM
  //  `Mb.     ,' YA.   ,A9   MM    MM    MM    MM   ,AP   MM    MM    MM    YM.    , `Mb    MM
  //    `"bmmmd'   `Ybmd9'  .JMML  JMML  JMML.  MMbmmd'    `Mbod"YML.  `Mbmo  `Mbmmd'  `Wbmd"MML.
  //                                            MM
  //                                          .JMML.

  computed: {
    // STORE ACCESS
    latitude() {
      return this.$store.getters.lat;
    },
    longitude() {
      return this.$store.getters.long;
    },
    date() {
      return this.$store.state.date;
    },
    startDate() {
      return this.$store.state.startDate;
    },
    minuteToOffset() {
      return this.$store.state.minuteToOffset;
    },

    offsetDate() {
      return d3.timeMinute.offset(this.startDate, this.minuteToOffset);
    },

    // offsetDate() {
    //   return this.$store.state.offsetDate;
    // },

    isitdark() {
      return this.$store.state.options.global.thedark;
    },

    landScapeMode() {
      return this.$store.state.landScapeMode;
    },

    compassoptions() {
      return this.$store.state.options.compass;
    },
    optionWatcher() {
      return this.$store.state.optionWatcher;
    },

    nbOfStars() {
      return this.$store.getters.nbOfStars;
    },

    maxRankForConstelLabel() {
      if (this.zoomState) {
        return 3;
      }
      return this.$store.getters.maxRankForConstelLabel;
    },
    opacityOfConstel() {
      return d3.scaleLinear([0, 6], [0, 0.9]);
    },

    magMax() {
      if (
        !this.compassoptions.autoNight ||
        this.compassoptions.compassPreview
      ) {
        return 6;
      }
      let n = this.whereIsTheSun(this.offsetDate)[1];
      if (n > 6) {
        return -1;
      } else if (n > -4) {
        return d3.scaleLinear([6, -4], [-1, 0.1])(n);
        // return d3.scaleLinear([0, -6], [0.1, 2])(n);
      } else if (n > -20) {
        return d3.scaleLinear([-4, -20], [0.1, 6])(n);
      } else {
        return 6;
      }
    },

    //   .o88b.  .d88b.  db       .d88b.  d8888b. .d8888.
    //  d8P  Y8 .8P  Y8. 88      .8P  Y8. 88  `8D 88'  YP
    //  8P      88    88 88      88    88 88oobY' `8bo.
    //  8b      88    88 88      88    88 88`8b     `Y8b.
    //  Y8b  d8 `8b  d8' 88booo. `8b  d8' 88 `88. db   8D
    //   `Y88P'  `Y88P'  Y88888P  `Y88P'  88   YD `8888Y'
    //     |  |  |  |  |  |  |  |  |  |  |  |  |  |  |
    //     |  |  |  |  |  |  |  |  |  |  |  |  |  |  |
    //     v  v  v  v  v  v  v  v  v  v  v  v  v  v  v

    colors() {
      return {
        fond: this.$vuetify.theme.dark ? "#121212" : "white",
        graticule: this.$vuetify.theme.dark ? "#595959" : "#D9D9D9",
        axis: this.$vuetify.theme.dark ? "#FFF" : "#121212",
        sun: this.whereIsTheSun(this.offsetDate)[1] > 0 ? "#FFCB7D" : "#185480",
        moonFond: "#202020",
        moonShape: "#FFF",
        moonLine: this.$vuetify.theme.dark
          ? this.whereIsTheMoon(this.offsetDate)[1] > 0
            ? "#828282"
            : "#202020"
          : this.whereIsTheMoon(this.offsetDate)[1] > 0
          ? "#626262"
          : "#D9D9D9",
        stars: this.$vuetify.theme.dark ? "#FFF" : "#121212",
        constelline: this.$vuetify.theme.dark
          ? `rgba(200, 200, 200, ${this.opacityOfConstel(this.magMax)})`
          : `rgba(100, 100, 100, ${this.opacityOfConstel(this.magMax)})`,
        verticalTick: this.$vuetify.theme.dark ? "#BDBDBD" : "#858585",
      };
    },

    //------------DATA FOLDER--------------//

    dastars() {
      let starscopy = JSON.parse(JSON.stringify(stars));
      starscopy.sort((a, b) => d3.ascending(a.mag, b.mag));
      let result = starscopy.slice(0, this.nbOfStars);
      return result;
    },

    // geostars() {

    //   let result = {
    //     type: "FeatureCollection",
    //     features: []
    //   }
    //   for (let star of this.dastars){
    //     let coords = this.projection(this.convert(star.coordinates));
    //     let x0 = coords[0];
    //     let y0 = coords[1];
    //     result.features.push(
    //       {
    //         type: "Feature",
    //         id: star.id,
    //         properties: {
    //           mag: star.mag
    //         },
    //         geometry: {
    //           type: "Point",
    //           coordinates: [x0, y0]
    //         }
    //       }
    //     )

    //     }

    //     return result

    // },

    geoConstelLines() {
      let constelcopy = JSON.parse(JSON.stringify(constelLines))
        .filter((x) => x[0] <= this.maxRankForConstelLabel)
        .map((x) => x[1]);
      let contelProjected = constelcopy.map((aConstellation) =>
        aConstellation.map((aLine) =>
          aLine.map((coords) => this.convert(coords))
        )
      );
      let result = {
        type: "FeatureCollection",
        features: [],
      };
      for (let constel of contelProjected) {
        result.features.push({
          type: "Feature",
          geometry: {
            type: "MultiLineString",
            coordinates: constel,
          },
        });
      }

      return result;
    },

    //------------SCALES--------------//

    magnitudeScale() {
      return d3
        .scaleLinear()
        .domain([-1.5, 5])
        .range([4, 1]);
    },

    //------------PROJECTION--------------//

    projection() {
      let height = this.ctxHeight;
      let width = this.ctxWidth;

      let bottomDecalageNotZoom =
        width / height > 1.57
          ? width / (1.57 * 9)
          : height < 500
          ? height / 8
          : height / 9;

      let bottomDecalage = this.zoomState
        ? bottomDecalageNotZoom + 23
        : bottomDecalageNotZoom;

      return d3
        .geoProjection((x, y) => d3.geoAzimuthalEqualAreaRaw(-x, -y))
        .scale(this.projectionScale)
        .rotate([this.rot1, this.rot2])
        .translate([width / 2, height - bottomDecalage])
        .precision(0.1);
    },

    //------------EPH COMPUTED--------------//
    //------------EPH COMPUTED--------------//
    //------------EPH COMPUTED--------------//

    daEph() {
      // parameters: ephemeris.getAllPlanets(dateObj, longitude, latitude, height);
      let eph = ephemeris.getAllPlanets(
        this.offsetDate,
        this.longitude,
        this.latitude,
        0
      ).observed;
      let variation = this.zoomState
        ? 2
        : this.compassoptions.planets.variation;

      let objects = ["venus", "mars", "jupiter", "saturn"];
      let frenchNames = {
        mercury: "Mercure",
        venus: "Vénus",
        mars: "Mars",
        jupiter: "Jupiter",
        saturn: "Saturne",
        uranus: "Uranus",
        neptune: "Neptune",
      };

      switch (variation) {
        case 0:
          objects = ["venus", "mars", "jupiter", "saturn"];
          break;
        case 1:
          objects = ["mercury", "venus", "mars", "jupiter", "saturn"];
          break;
        case 2:
          objects = [
            "mercury",
            "venus",
            "mars",
            "jupiter",
            "saturn",
            "uranus",
            "neptune",
          ];
          break;
      }

      let result = objects.map(function(planet) {
        let coords = eph[planet].raw.position.altaz.topocentric;
        return {
          nom: frenchNames[planet],
          coordinates: [coords.azimuth, coords.altitude],
        };
      });
      return result;
    },

    //------------SUNCALC COMPUTED--------------//
    //------------SUNCALC COMPUTED--------------//
    //------------SUNCALC COMPUTED--------------//
    sunPath() {
      // I SHOULD HAVE THE ECLIPTIC2EQUATORIAL FUNCTION BUT I DONT
      // const ecliptic = d3.range(5).map(i => [i / 4 * 360, 0]).map(d => this.convert(d));
      // console.log(ecliptic)
      // return {
      //   type: "LineString",
      //   coordinates: ecliptic,
      // };

      let sunStart = suncalc.getTimes(
        this.offsetDate,
        this.latitude,
        this.longitude
      ).sunrise;
      let sunEnd = suncalc.getTimes(
        this.offsetDate,
        this.latitude,
        this.longitude
      ).sunset;
      if (isNaN(sunStart) || isNaN(sunEnd)) {
        sunStart = d3.timeHour.offset(this.offsetDate, -12);
        sunEnd = d3.timeHour.offset(this.offsetDate, 12);
      }
      return {
        type: "LineString",
        coordinates: d3.utcMinutes(sunStart, sunEnd).map(this.whereIsTheSun),
      };
    },

    sunPos() {
      return this.projection(this.whereIsTheSun(this.offsetDate));
    },

    moonPath() {
      var start = d3.timeHour.offset(this.offsetDate, -6),
        end = d3.timeHour.offset(this.offsetDate, 6);
      return {
        type: "LineString",
        coordinates: d3
          .utcMinutes(start, end)
          .map((d) => this.whereIsTheMoon(d)),
      };
    },

    // moonPosition() {
    //   return `translate(${this.projection(
    //     this.whereIsTheMoon(this.offsetDate)
    //   )}) rotate(${(suncalc.getMoonPosition(
    //     this.offsetDate,
    //     this.latitude,
    //     this.longitude
    //   ).parallacticAngle *
    //     180) /
    //     Math.PI})`;
    // },

    moonPosition() {
      return this.projection(this.whereIsTheMoon(this.offsetDate));
    },
    moonRotation() {
      // get the A angle wich is the orientation of the moon from the projection (simple (lol) trigo)
      const m = this.whereIsTheMoon(this.offsetDate);
      const m2 = [m[0] + 1, m[1]];
      const mp = this.projection(m);
      const m2p = this.projection(m2);
      const v = m2p[0] - mp[0];
      const u = m2p[1] - mp[1];
      const A = Math.atan2(u, v);

      return (
        suncalc.getMoonPosition(this.offsetDate, this.latitude, this.longitude)
          .parallacticAngle + A
      );
    },

    graticule() {
      return d3.geoGraticule().stepMinor([45, 10])();
    },

    // ticks() {
    //   let range = d3.range(0, 360, 5);
    //   let aRange = range.map(function(d) {
    //     return {
    //       type: "Feature",
    //       id: d,
    //       properties: {
    //         label: d % 15 ? false : true,
    //         style:
    //           d % 15
    //             ? ""
    //             : d % 90
    //             ? "10px sans-serif"
    //             : "15px bolder, sans-serif",
    //         text:
    //           d % 15
    //             ? ""
    //             : d === 0
    //             ? "N"
    //             : d === 45
    //             ? "NE"
    //             : d === 90
    //             ? "E"
    //             : d === 135
    //             ? "SE"
    //             : d === 180
    //             ? "S"
    //             : d === 225
    //             ? "SO"
    //             : d === 270
    //             ? "O"
    //             : d === 315
    //             ? "NO"
    //             : d + "°",
    //       },
    //       geometry: {
    //         type: "LineString",
    //         coordinates: [
    //           [d, 0],
    //           [d, d % 15 ? -1 : -2],
    //         ],
    //       },
    //     };
    //   });

    //   let result = {
    //     type: "FeatureCollection",
    //     features: aRange,
    //   };
    //   return result;
    // },
    ticks() {
      let notDegreVariation = this.compassoptions.graticule.label;
      let zoom = this.zoomState;
      let range = notDegreVariation
        ? d3.range(0, 360, 45)
        : d3.range(0, 360, 1);
      let labelEvery = notDegreVariation ? 45 : 15;
      let aRange = range.map(function(d) {
        return {
          type: "Feature",
          id: d,
          properties: {
            label: d % labelEvery ? false : true,
            style:
              d % 15
                ? ""
                : d % 90
                ? "0.7em sans-serif"
                : "1em bolder, sans-serif",
            text:
              d % 15
                ? ""
                : d === 0
                ? notDegreVariation
                  ? "NORD"
                  : "N"
                : d === 45
                ? "NE"
                : d === 90
                ? notDegreVariation
                  ? "EST"
                  : "E"
                : d === 135
                ? "SE"
                : d === 180
                ? notDegreVariation
                  ? "SUD"
                  : "S"
                : d === 225
                ? "SO"
                : d === 270
                ? notDegreVariation
                  ? "OUEST"
                  : "O"
                : d === 315
                ? "NO"
                : d + "°",
          },
          geometry: {
            type: "LineString",
            coordinates: [
              [d, 0],
              [d, (d % 15 ? -1 : -2) / (zoom ? 1.4 : 1)],
            ],
          },
        };
      });

      let result = {
        type: "FeatureCollection",
        features: aRange,
      };
      return result;
    },

    verticalTicks() {
      let range = d3.range(10, 91, 10);
      let result = [];
      if (this.zoomState) {
        range.forEach((d) => {
          result.push({
            text: d + "°",
            coordinates: [180, d],
          });
          result.push({
            text: d + "°",
            coordinates: [0, d],
          });
          result.push({
            text: d + "°",
            coordinates: [90, d],
          });
          result.push({
            text: d + "°",
            coordinates: [-90, d],
          });
        });
      } else {
        result = range.map(function(d) {
          return {
            text: d + "°",
            coordinates: [180, d],
          };
        });
      }
      return result;
    },
  },

  //
  //                                     ,,                         ,,
  //  `7MMM.     ,MMF'           mm    `7MM                       `7MM
  //    MMMb    dPMM             MM      MM                         MM
  //    M YM   ,M MM   .gP"Ya  mmMMmm    MMpMMMb.   ,pW"Wq.    ,M""bMM  ,pP"Ybd
  //    M  Mb  M' MM  ,M'   Yb   MM      MM    MM  6W'   `Wb ,AP    MM  8I   `"
  //    M  YM.P'  MM  8M""""""   MM      MM    MM  8M     M8 8MI    MM  `YMMMa.
  //    M  `YM'   MM  YM.    ,   MM      MM    MM  YA.   ,A9 `Mb    MM  L.   I8
  //  .JML. `'  .JMML. `Mbmmd'   `Mbmo .JMML  JMML. `Ybmd9'   `Wbmd"MML.M9mmmP'
  //
  //

  methods: {
    // resizeCanvas() {
    //   this.canvas.width = this.ctxWidth;
    //   this.canvas.height = this.ctxHeight;
    // },
    convert(coords) {
      const invertAzimuth = function([azimuth, altitude]) {
        return [180 + azimuth, altitude];
      };
      return invertAzimuth(
        suncalc.equatorial2horizontal(
          // <------- THIS FUNCTION has been add "alamano" to suncalc
          this.offsetDate,
          this.latitude,
          this.longitude
        )(coords)
      );
    },

    //
    //     ____     ____         ___
    //    |    \   |    \       /   |  |         /
    //    |     \  |     \     /    |  |        /
    //    |     |  |     |    /     |  |       /
    //    |     |  |____/    /______|  |  /|  /
    //    |    /   |    \   /       |  | / | /
    //    |___/    |     \ /        |  |/  |/
    //
    // DRAW FUNCTIONS <-------------------------------  DRAW METHODS
    // DRAW FUNCTIONS <-------------------------------  DRAW METHODS

    //   __   __        __  ___  ___
    //  /  ` /  \ |\ | /__`  |  |__  |    |
    //  \__, \__/ | \| .__/  |  |___ |___ |___
    //

    drawConstellations(context, path) {
      (context.lineWidth = 0.5), context.beginPath();
      path(this.geoConstelLines);
      context.strokeStyle = this.colors.constelline;
      context.stroke();

      // //constellation names
      if (this.compassoptions?.constellations?.label && !this.spinning) {
        context.font = "italic 1em serif";
        context.textAlign = "center";
        context.textBaseline = "middle";
        context.fillStyle = this.colors.constelline;
        constelLabel.forEach((constelation) => {
          if (constelation.rank > this.maxRankForConstelLabel) {
            return;
          }
          let coordc = this.projection(this.convert(constelation.coordinates));
          let xtc = coordc[0];
          let ytc = coordc[1];
          context.fillText(constelation.fr, xtc, ytc);
        });
      } //  <------- FIN de if constel label ON
    },

    //   __   __       ___    __             ___
    //  / _` |__)  /\   |  | /  ` |  | |    |__
    //  \__> |  \ /~~\  |  | \__, \__/ |___ |___
    //

    drawGraticules(context, path) {
      context.lineWidth = 0.5;
      context.beginPath(), (context.lineCap = "round");

      path(verticalGrat),
        (context.strokeStyle = this.colors.graticule),
        context.stroke();
    },

    //   __  ___       __   __
    //  /__`  |   /\  |__) /__`
    //  .__/  |  /~~\ |  \ .__/
    //

    // // OPTIONS WITH GEOJSON TO CHECK IF IT'S FASTER BUT NOT REALLY
    // context.beginPath();
    // starPath(this.geostars);
    // context.fill();
    drawStars(context) {
      for (let star of this.dastars) {
        let coords = this.projection(this.convert(star.coordinates));
        let x0 = coords[0];
        let y0 = coords[1];
        let r = this.magnitudeScale(star.mag);
        context.beginPath();
        // context.moveTo(x0, y0);
        context.arc(x0, y0, r, 0, 2 * Math.PI);
        context.fillStyle = this.colors.stars;
        context.fill();

        //  STAR NAMES | not if dragging
        //             V
        if (
          this.compassoptions?.stars?.label &&
          // !this.spinning &&
          star.mag < 1.6 &&
          "nom" in star
        ) {
          context.font = "1em 'Fira Sans', sans-serif";
          context.textAlign = "center";
          context.textBaseline = "top";
          context.fillStyle = "#999";
          context.fillText(star.nom, x0, y0 + 5);
        } // <---- fin star Name

        //stop drawing stars if magnitude limit is reached
        if (star.mag > this.magMax) break;
      }
    },

    //   __                  ___ ___
    //  |__) |     /\  |\ | |__   |
    //  |    |___ /~~\ | \| |___  |
    //

    drawPlanets(context) {
      for (let planet of this.daEph) {
        let coords = this.projection(planet.coordinates);
        let xp = coords[0];
        let yp = coords[1];
        context.beginPath();
        // context.moveTo(xp, yp);
        context.arc(xp, yp, 3, 0, 2 * Math.PI);
        context.fillStyle = "red";
        context.fill();

        //  PLANETS NAMES | not if dragging
        //             V
        if (
          this.compassoptions?.planets?.label &&
          // !this.spinning &&
          "nom" in planet
        ) {
          context.font = "1em 'Fira Sans', sans-serif";
          // context.font = "1em sans-serif";
          context.textAlign = "center";
          context.textBaseline = "bottom";
          context.fillStyle = "red";
          context.fillText(planet.nom, xp, yp - 5);
        } // <---- fin planet Name
      }
    },

    //   __       ___
    //  |__)  /\   |  |__|
    //  |    /~~\  |  |  |
    //

    drawMoonPath(context, path) {
      context.lineWidth = 2;
      context.beginPath();
      path(this.moonPath);
      context.strokeStyle = this.colors.moonLine;
      context.stroke();
    },

    drawSunPath(context, path) {
      context.lineWidth = 2;
      context.beginPath();
      path(this.sunPath);
      context.strokeStyle = this.colors.sun;
      context.stroke();
    },

    //  ___    __        __
    //   |  | /  ` |__/ /__`
    //   |  | \__, |  \ .__/
    //
    drawTicks(context, path) {
      // azimut ticks
      (context.lineWidth = 0.8),
        context.beginPath(),
        path(this.ticks),
        (context.strokeStyle = this.colors.axis),
        context.stroke();

      // azimut ticks texte
      this.ticks.features.forEach((daTick) => {
        if (daTick.properties.label) {
          let coordt = this.projection([
            daTick.id,
            this.zoomState ? -1.8 : -2.5,
          ]);
          let xt = coordt[0];
          let yt = coordt[1];
          context.font = daTick.properties.style;
          context.textAlign = "center";
          context.textBaseline = "top";
          context.fillStyle = this.colors.axis;
          context.fillText(daTick.properties.text, xt, yt);
        }
      });
    },

    drawVerticalScale(context) {
      this.verticalTicks.forEach((tick) => {
        let coordt = this.projection(tick.coordinates);
        let xtv = coordt[0];
        let ytv = coordt[1];
        context.beginPath();
        context.arc(xtv, ytv, 10, 0, 2 * Math.PI);
        context.fillStyle = this.colors.fond;
        context.fill();
        context.font = "10px sans-serif";
        context.textAlign = "center";
        context.textBaseline = "middle";
        context.fillStyle = this.colors.verticalTick;
        context.fillText(tick.text, xtv, ytv);
      });
    },

    //    _____    ______  __      __  _____    _____   ______
    //   |  __ \  |  ____| \ \    / / |_   _|  / ____| |  ____|
    //   | |  | | | |__     \ \  / /    | |   | |      | |__
    //   | |  | | |  __|     \ \/ /     | |   | |      |  __|
    //   | |__| | | |____     \  /     _| |_  | |____  | |____
    //   |_____/  |______|     \/     |_____|  \_____| |______|
    //               _   _    _____   _        ______
    //       /\     | \ | |  / ____| | |      |  ____|
    //      /  \    |  \| | | |  __  | |      | |__
    //     / /\ \   | . ` | | | |_ | | |      |  __|
    //    / ____ \  | |\  | | |__| | | |____  | |____
    //   /_/    \_\ |_| \_|  \_____| |______| |______|

    drawDeviceOrientation(context, path) {
      //TEST POUR ANGLE
      context.lineWidth = 1.5;
      let angletTest = this.compassOrientation;
      let yCompassUI = (this.ctxHeight * 3) / 4;
      let sizeUI = 10;
      let xTest = this.projection([angletTest, 0])[0];

      if (xTest > this.ctxWidth - 20) {
        let xDroite = this.ctxWidth - 20;

        context.beginPath();
        context.moveTo(xDroite, yCompassUI);
        context.lineTo(xDroite, yCompassUI - 2 * sizeUI);
        context.lineTo(xDroite + sizeUI, yCompassUI - sizeUI);
        context.closePath();

        context.strokeStyle = "red";
        context.stroke();
        // context.fillStyle = "red";
        // context.fill();
      } else if (xTest < 20) {
        let xGauche = 20;

        context.beginPath();
        context.moveTo(xGauche, yCompassUI);
        context.lineTo(xGauche, yCompassUI - 2 * sizeUI);
        context.lineTo(xGauche - sizeUI, yCompassUI - sizeUI);
        context.closePath();

        context.strokeStyle = "red";
        context.stroke();
        // context.fillStyle = "red";
        // context.fill();
      } else {
        let daLine = {
          type: "LineString",
          coordinates: [
            [angletTest, 0],
            [angletTest, this.zoomState ? 9 : 29],
          ],
        };

        context.beginPath();
        path(daLine);
        context.strokeStyle = "red";
        context.stroke();
      }
    },

    //
    //
    //  `7MM"""Yb.
    //    MM    `Yb.
    //    MM     `Mb `7Mb,od8  ,6"Yb.  `7M'    ,A    `MF'
    //    MM      MM   MM' "' 8)   MM    VA   ,VAA   ,V
    //    MM     ,MP   MM      ,pm9MM     VA ,V  VA ,V
    //    MM    ,dP'   MM     8M   MM      VVV    VVV
    //  .JMMmmmdP'   .JMML.   `Moo9^Yo.     W      W
    //   ,,
    //  *MM                          `7MM
    //   MM                            MM
    //   MM,dMMb.   ,6"Yb.   ,p6"bo    MM  ,MP'
    //   MM    `Mb 8)   MM  6M'  OO    MM ;Y
    //   MM     M8  ,pm9MM  8M         MM;Mm
    //   MM.   ,M9 8M   MM  YM.    ,   MM `Mb.
    //   P^YbmdP'  `Moo9^Yo. YMbmd'  .JMML. YA.
    //
    //

    drawBackground() {
      const context = this.backContext;
      const path = d3.geoPath(this.projection, context);

      context.clearRect(0, 0, this.backCanvas.width, this.backCanvas.height);

      if (this.compassoptions.graticule.show) {
        context.lineWidth = 0.5;
        context.beginPath(), (context.lineCap = "round");

        path(horizontalGrat),
          (context.strokeStyle = this.colors.graticule),
          context.stroke();
      }
    },

    //
    //  `7MM"""Yb.   `7MM"""Mq.        db      `7MMF'     A     `7MF'
    //    MM    `Yb.   MM   `MM.      ;MM:       `MA     ,MA     ,V
    //    MM     `Mb   MM   ,M9      ,V^MM.       VM:   ,VVM:   ,V
    //    MM      MM   MMmmdM9      ,M  `MM        MM.  M' MM.  M'
    //    MM     ,MP   MM  YM.      AbmmmqMA       `MM A'  `MM A'
    //    MM    ,dP'   MM   `Mb.   A'     VML       :MM;    :MM;
    //  .JMMmmmdP'   .JMML. .JMM..AMA.   .AMMA.      VF      VF

    drawCompass(spin) {
      this.spinning = spin;

      if (this.scrollTimer !== null) {
        clearTimeout(this.scrollTimer);
      }

      const context = this.context;
      const path = d3.geoPath(this.projection, context);

      // const starPath = d3.geoPath(this.projection, this.context)
      // .pointRadius(d => this.magnitudeScale(d.properties.mag));
      // context.imageSmoothingEnabled = false;
      // let projection = this.projection;

      // effacer le canevas entièrement
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

      context.save(); // STARTING A CLIPPING MASQUE <-------------------- CLIP
      context.beginPath();
      path(this.equator);
      context.clip();

      // GRATICULE TERRESTRE
      if (this.compassoptions?.graticule?.show) {
        this.drawGraticules(context, path);
        this.drawVerticalScale(context);
      }
      // DEVICE ORIENTATION
      if (this.deviceOrientationActive) {
        this.drawDeviceOrientation(context, path);
      }

      //SUN -- PATH
      if (this.compassoptions.sunTr.show) {
        this.drawSunPath(context, path);
      } // <---- FIN de IF sun trajectory

      //MOON -- PATH
      if (this.compassoptions.moonTr.show) {
        this.drawMoonPath(context, path);
      } // <---- FIN de IF moon trajectory

      // CONSTELLATIONS
      if (this.compassoptions?.constellations?.show && this.magMax >= 0) {
        this.drawConstellations(context, path);
      } //  <------- FIN de if constel ON

      //STARS
      if (this.compassoptions?.stars?.show && this.magMax >= -0.9) {
        this.drawStars(context);
      }

      //PLANETS
      if (this.compassoptions?.planets?.show && this.magMax >= -0.9) {
        this.drawPlanets(context);
      }

      //   __
      //  /__` |  | |\ |
      //  .__/ \__/ | \|
      //

      //SUN -- CIRCLE
      context.beginPath();
      context.arc(this.sunPos[0], this.sunPos[1], 15, 0, 2 * Math.PI);
      context.fillStyle = this.colors.sun;
      context.fill();

      const drawMoonPhase = () => {
        context.save();
        context.translate(this.moonPosition[0], this.moonPosition[1]);
        context.rotate(this.moonRotation);
        context.beginPath();

        context.fillStyle = this.colors.moonShape;
        this.moonPhase();
        context.fill();
        context.restore();
      };

      if (this.whereIsTheMoon(this.offsetDate)[1] > 0) {
        // <---- ###(if_moonup)###

        //MOON -- CIRCLE
        context.lineWidth = 2;
        context.beginPath();
        context.arc(
          this.moonPosition[0],
          this.moonPosition[1],
          16,
          0,
          2 * Math.PI
        );
        context.fillStyle = this.colors.moonFond;
        context.fill();

        //MOON -- SHAPE
        drawMoonPhase();
      } // <---- fin de  ###(if_moonup)###

      //   ___
      //  |__  | |\ |
      //  |    | | \|
      //

      //contour (on top of drawing and just before uncliping)
      (context.lineWidth = 2),
        context.beginPath(),
        (context.strokeStyle = this.colors.axis),
        context.strokeRect(0, 0, this.ctxWidth, this.ctxHeight);

      context.restore(); // STARTING A CLIPPING MASQUE <-------------------- UNCLIP

      // horizon
      (context.lineWidth = 1),
        context.beginPath(),
        path(this.equator),
        (context.strokeStyle = this.colors.axis),
        context.stroke();

      //DRAW TICKS
      this.drawTicks(context, path);

      //SPIIIIIIIIIIIIIIIIIIIIIIIIIIIN

      if (spin) {
        this.scrollTimer = setTimeout(() => {
          this.drawCompass(false);
        }, 100);
      }
      //     setTimeout(() => {
      //   requestAnimationFrame(this.drawCompass);
      // }, 1000 / 60);

      //   ___            __   __
      //  |__  | |\ |    |  \ |__)  /\  |  |
      //  |    | | \|    |__/ |  \ /~~\ |/\|
      //
    }, // <-------------- FIN DRAW

    // SUNCALC  <-------------------------------  SUNCALC
    //
    //     +-++-++-++-++-++-++-+
    //     |s||u||n||c||a||l||c|    METHODS
    //     +-++-++-++-++-++-++-+
    //
    // SUNCALC  <-------------------------------  SUNCALC

    whereIsTheSun(ladate) {
      return [
        (suncalc.getPosition(ladate, this.latitude, this.longitude).azimuth *
          180) /
          Math.PI +
          180,
        (suncalc.getPosition(ladate, this.latitude, this.longitude).altitude *
          180) /
          Math.PI,
      ];
    },
    whereIsTheMoon(ladate) {
      return [
        (suncalc.getMoonPosition(ladate, this.latitude, this.longitude)
          .azimuth *
          180) /
          Math.PI +
          180,
        (suncalc.getMoonPosition(ladate, this.latitude, this.longitude)
          .altitude *
          180) /
          Math.PI,
      ];
    },

    moonPhase() {
      const angle =
        180 -
        suncalc.getMoonIllumination(d3.timeDay(this.offsetDate)).phase * 360;
      const msprojection = d3
        .geoOrthographic()
        .translate([0, 0])
        .scale(15)
        // .scale(100)
        .rotate([angle, 0])
        .precision(6);

      const mspath = d3.geoPath(msprojection, this.context);
      const hemisphere = d3.geoCircle()();
      return mspath(hemisphere);
    },

    // DRAG  <------------------------------- DRAG
    //
    //  `7MM"""Yb.   `7MM"""Mq.        db        .g8"""bgd
    //    MM    `Yb.   MM   `MM.      ;MM:     .dP'     `M
    //    MM     `Mb   MM   ,M9      ,V^MM.    dM'       `
    //    MM      MM   MMmmdM9      ,M  `MM    MM
    //    MM     ,MP   MM  YM.      AbmmmqMA   MM.    `7MMF'
    //    MM    ,dP'   MM   `Mb.   A'     VML  `Mb.     MM
    //  .JMMmmmdP'   .JMML. .JMM..AMA.   .AMMA.  `"bmmmdPY
    //
    //     ____     ____         ___     ______
    //    |    \   |    \       /   |   /      \
    //    |     \  |     \     /    |  |        \
    //    |     |  |     |    /     |  |
    //    |     |  |____/    /______|  |     ____
    //    |    /   |    \   /       |  |        /
    //    |___/    |     \ /        |   \______/
    //
    // DRAG  <-------------------------------  DRAG

    dragstart(e) {
      this.spinning = true;
      this.isDragging = true;
      this.canvas.classList.add("active");
      this.x0 = e.pageX;
      this.cancelTurnToTransition();
      this.cancelMomentumTracking();
      this.velX = 0;
    },
    dragend() {
      this.isDragging = false;
      this.canvas.classList.remove("active");
      this.beginMomentumTracking();
    },

    dragleave() {
      this.isDragging = false;
      this.canvas.classList.remove("active");
    },

    drag(e) {
      if (!this.isDragging) return;
      e.preventDefault();
      const x1 = e.clientX;
      const walk = this.zoomState ? (x1 - this.x0) / 8 : (x1 - this.x0) / 3 ; //scroll-normal
      var prevRot = this.rot1;
      this.projection.rotate([this.rot1 + walk, this.rot2]);
      this.drawCompass(true);
      this.x0 = x1;
      this.rot1 = this.projection.rotate()[0];
      this.velX = this.rot1 - prevRot;
    },

    // Momentum for drag
    beginMomentumTracking() {
      this.cancelMomentumTracking();
      this.momentumID = requestAnimationFrame(this.momentumLoop);
    },
    cancelMomentumTracking() {
      cancelAnimationFrame(this.momentumID);
    },
    momentumLoop() {
      this.velX *= 0.95;
      if (Math.abs(this.velX) > 0.5) {
        this.rot1 += this.velX;
        this.drawCompass(true);
        this.momentumID = requestAnimationFrame(this.momentumLoop);
      }
      // else {
      //   this.drawCompass(false);
      // }
    },

    touchstart(e) {
      this.spinning = true;
      e.preventDefault();
      this.isDragging = true;
      this.x0 = e.touches[0].pageX;
      this.cancelTurnToTransition();
      this.cancelMomentumTracking();
      this.velX = 0;
    },
    touchmove(e) {
      if (!this.isDragging) return;
      // if (!this.throttleTouch) {
      this.drawCompass(true);
      // this.wait = true;
      e.preventDefault();
      const x1 = e.touches[0].pageX;
      const walk = (x1 - this.x0) / 2; //scroll-normal
      var prevRot = this.rot1;
      this.projection.rotate([this.rot1 + walk, this.rot2]);
      this.x0 = x1;
      this.rot1 = this.projection.rotate()[0];
      this.velX = this.rot1 - prevRot;
      // setTimeout(function() {
      //   this.wait = false;
      // }, 1000 / 30);
      // }
    },
    touchend() {
      this.isDragging = false;
      this.beginMomentumTracking();
    },

    //
    //  d8888b.      d88888b      .d8888.      d888888b      d88888D      d88888b
    //  88  `8D      88'          88'  YP        `88'        YP  d8'      88'
    //  88oobY'      88ooooo      `8bo.           88            d8'       88ooooo
    //  88`8b        88~~~~~        `Y8b.         88           d8'        88~~~~~
    //  88 `88.      88.          db   8D        .88.         d8' db      88.
    //  88   YD      Y88888P      `8888Y'      Y888888P      d88888P      Y88888P
    //

    resizeCanvas() {
      this.zoomState = false;
      this.canvas = document.getElementById("projection-canvas");
      this.backCanvas = document.getElementById("background-canvas");
      // const rect = this.canvas.getBoundingClientRect();

      this.ctxWidth = document.getElementsByClassName(
        "two-canvas-container"
      )[0].clientWidth;
      this.ctxHeight = document.getElementsByClassName(
        "two-canvas-container"
      )[0].clientHeight;

      // see : https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio

      this.canvas.style.width = this.ctxWidth + "px";
      this.canvas.style.height = this.ctxHeight + "px";
      this.backCanvas.style.width = this.ctxWidth + "px";
      this.backCanvas.style.height = this.ctxHeight + "px";

      //give those sizes to ce context
      this.canvas.width = Math.floor(this.ctxWidth * this.pxRatio);
      this.canvas.height = Math.floor(this.ctxHeight * this.pxRatio);
      this.backCanvas.width = Math.floor(this.ctxWidth * this.pxRatio);
      this.backCanvas.height = Math.floor(this.ctxHeight * this.pxRatio);

      this.projectionScale =
        this.ctxWidth / this.ctxHeight < 1.57
          ? 0.595 * this.ctxHeight
          : (0.595 * this.ctxWidth) / 1.57;
    },

    onResize() {
      this.pxRatio = window.devicePixelRatio || 1;

      this.resizeCanvas();

      this.context = this.canvas.getContext("2d");
      this.backContext = this.backCanvas.getContext("2d");
      this.context.scale(this.pxRatio, this.pxRatio);
      this.backContext.scale(this.pxRatio, this.pxRatio);
      this.drawBackground();
      this.drawCompass(false);
    },

    goLandscape() {
      return;
      // if(window.innerHeight > '200px'){return}
      // if (this.landScapeMode) {
      //   document
      //     .getElementsByClassName("bottom-nav")[0]
      //     .classList.add("landscape");
      //   document.getElementsByClassName("parent")[0].classList.add("landscape");
      //   document
      //     .getElementsByClassName("overlight")[0]
      //     .classList.add("landscape");
      //   this.onResize();
      //   this.drawCompass(false);
      // } else {
      //   document
      //     .getElementsByClassName("bottom-nav")[0]
      //     .classList.remove("landscape");
      //   document
      //     .getElementsByClassName("parent")[0]
      //     .classList.remove("landscape");
      //   document
      //     .getElementsByClassName("overlight")[0]
      //     .classList.remove("landscape");
      //   this.onResize();
      //   this.drawCompass(false);
      // }
    },
    //  d88888D  .d88b.   .d88b.  .88b  d88.
    //  YP  d8' .8P  Y8. .8P  Y8. 88'YbdP`88
    //     d8'  88    88 88    88 88  88  88
    //    d8'   88    88 88    88 88  88  88
    //   d8' db `8b  d8' `8b  d8' 88  88  88
    //  d88888P  `Y88P'   `Y88P'  YP  YP  YP
    //
    zoom() {
      if (this.zoomState) {
        this.zoomState = false;
        this.projectionScale /= 1.8;
      } else {
        this.zoomState = true;
        this.projectionScale *= 1.8;
      }
      this.drawBackground();
      this.drawCompass();
    },

    //
    //  d8b   db  .d88b.  d8888b. d888888b db   db
    //  888o  88 .8P  Y8. 88  `8D `~~88~~' 88   88
    //  88V8o 88 88    88 88oobY'    88    88ooo88
    //  88 V8o88 88    88 88`8b      88    88~~~88
    //  88  V888 `8b  d8' 88 `88.    88    88   88
    //  VP   V8P  `Y88P'  88   YD    YP    YP   YP
    //
    //

    startDeviceOrientation() {
      if (this.deviceOrientationActive) {
        this.turnAroundActive = false;
        this.deviceOrientationActive = false;
        this.drawCompass(false);
        return;
      }
      DeviceOrientationEvent.requestPermission()
        .then((response) => {
          if (response === "granted") {
            this.deviceOrientationActive = true;
            window.addEventListener("deviceorientation", this.handler, true);
          } else {
            alert("Il y a un problème...");
          }
        })
        .catch(() => {
          this.deviceOrientationActive = true;
          alert("Il y a un gros problème, ça ne marche pas du tout");
        });
    },

    handler(e) {
      this.compassOrientation = e.webkitCompassHeading;
      this.isTurnAroundActive();
      this.drawCompass(false);
    },

    //
    //  d888888b db    db d8888b. d8b   db      d888888b  .d88b.
    //  `~~88~~' 88    88 88  `8D 888o  88      `~~88~~' .8P  Y8.
    //     88    88    88 88oobY' 88V8o 88         88    88    88
    //     88    88    88 88`8b   88 V8o88         88    88    88
    //     88    88b  d88 88 `88. 88  V888         88    `8b  d8'
    //     YP    ~Y8888P' 88   YD VP   V8P         YP     `Y88P'
    //

    isTurnAroundActive() {
      if (!this.deviceOrientationActive) {
        return;
      }
      let test = this.projection([this.compassOrientation, 0])[0];
      if (test > this.ctxWidth + 10 || test < -10) {
        this.turnAroundActive = true;
        return;
      }
      let projectionAngle = this.rot1;
      let boussoleAngle = 180 - this.compassOrientation;
      let angleDePartEtDautre = 80;
      if (projectionAngle + angleDePartEtDautre > 180) {
        if (
          boussoleAngle > projectionAngle - angleDePartEtDautre ||
          boussoleAngle < projectionAngle + angleDePartEtDautre - 360
        ) {
          this.turnAroundActive = false;
        } else {
          this.turnAroundActive = true;
        }
      } else if (projectionAngle - angleDePartEtDautre < -180) {
        if (
          boussoleAngle < projectionAngle + angleDePartEtDautre ||
          boussoleAngle > projectionAngle - angleDePartEtDautre + 360
        ) {
          this.turnAroundActive = false;
        } else {
          this.turnAroundActive = true;
        }
      } else {
        if (
          boussoleAngle < projectionAngle + angleDePartEtDautre &&
          boussoleAngle > projectionAngle - angleDePartEtDautre
        ) {
          this.turnAroundActive = false;
        } else {
          this.turnAroundActive = true;
        }
      }
    },
    turnAround() {
      this.turnToObjectif = 180 - this.compassOrientation;
      let test = this.projection([this.compassOrientation, 0])[0];
      if (test < 0) {
        this.turnRight = false;
      } else {
        this.turnRight = true;
      }
      this.beginTurnToTransition();
    },
    beginTurnToTransition() {
      this.cancelMomentumTracking();
      this.cancelTurnToTransition();
      this.turnTransition = requestAnimationFrame(this.turnToTransitionLoop);
    },
    cancelTurnToTransition() {
      this.cancelMomentumTracking();
      cancelAnimationFrame(this.turnTransition);
    },
    turnToTransitionLoop() {
      if (
        Math.abs(this.rot1 - this.turnToObjectif) > 11 &&  Math.abs(this.rot1 - this.turnToObjectif) < 349
      ) {
        console.log(this.rot1, this.turnToObjectif)
        this.rot1 = this.turnRight
          ? Math.round(this.rot1 - 10)
          : Math.round(this.rot1 + 10);
          
        this.drawCompass(false);
        this.turnTransition = requestAnimationFrame(this.turnToTransitionLoop);
      } else if (
        Math.abs(this.rot1-this.turnToObjectif) > 2
      ) {
        this.rot1 = this.turnRight
          ? Math.round(this.rot1 - 1)
          : Math.round(this.rot1 + 1);

        this.drawCompass(false);
        this.turnTransition = requestAnimationFrame(this.turnToTransitionLoop);
      }
    },
  },
};
</script>

<style scoped>
/***
 *                                                
 *                                   ,,           
 *     .M"""bgd   mm               `7MM           
 *    ,MI    "Y   MM                 MM           
 *    `MMb.     mmMMmm  `7M'   `MF'  MM   .gP"Ya  
 *      `YMMNq.   MM      VA   ,V    MM  ,M'   Yb 
 *    .     `MM   MM       VA ,V     MM  8M"""""" 
 *    Mb     dM   MM        VVV      MM  YM.    , 
 *    P"Ybmmd"    `Mbmo     ,V     .JMML. `Mbmmd' 
 *                         ,V                     
 *                      OOb"                      
 */
* {
  scrollbar-width: none; /* Firefox hide scrollbar*/
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.lock-screen {
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: fixed;
  padding-bottom: 112px;
}

.content {
  height: 100%;
  padding: 4px 16px 16px;
  margin: auto;
}

.parent {
  margin-top: 3vh;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}

/* .parent.landscape {
  justify-content: start;
} */

.bottom-nav {
  height: 80px;
}
/* .bottom-nav.landscape {
  height: 80px;
  position: absolute;
  width: 100%;
  padding-right: 30px;
  background-color: rgba(255, 255, 255, 0.6);
  margin-top: 10px;
} */

.two-canvas-container {
  max-height: 650px;
  /* display: flex; */
  flex: 1;
}

#projection-canvas {
  /* height: 100%; */
  cursor: grab;
  cursor: -webkit-grab;
  position: absolute;
}
#projection-canvas.active {
  cursor: grabbing;
  /* scroll-behavior: unset; */
  cursor: -webkit-grabbing;
  background: none !important;
}

#background-canvas {
  position: absolute;
}

.turnAroundButtun:focus::before {
  opacity: 0;
}
</style>
