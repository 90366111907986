import Vue from 'vue';
import Vuetify from 'vuetify/lib';

// import '@mdi/font/css/materialdesignicons.css'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: "#185480",
            secondary: "#333",
            accent: colors.shades.black,
            error: colors.red.lighten2,
            bow: colors.black,
          },
          dark: {
            primary: colors.blue.lighten3,
            secondary: colors.grey,
            accent: colors.shades.blue,
            error: colors.red.lighten3,
            bow: colors.white,
          },
        },
      },
});
