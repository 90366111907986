<template>
  <div class='englobe'>
      <svg viewBox="0,0,124,124" style="width: 100; height: 100;" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
        <path
        fill="grey">
        <animate id="animation-to-check" repeatCount="indefinite" attributeName="d" dur="5"

    values="
    M17,62c0,29,21,50,45,50-27.61,0-48-22.39-48-50S34.39,12,62,12C39,12,17,32,17,62Z;

    M111,62c0,29-24,50-49,50-27.61,0-48-22.39-48-50S34.39,12,62,12C87,12,111,32,111,62Z;
    
    M111,62c0,29-24,50-49,50,22,0,46-22.39,46-50S84,12,62,12C87,12,111,32,111,62Z;
    "/>
</path>
</svg>
<div class="katsankat secondary--text">
    <h3 class="pb-10 pt-10 primary--text">Contrairement ce que vous cherchez, <br>la Lune est toujours là.</h3>
    <h4>Le diametre de la Lune : 3 500km</h4>
    <p>
        à peu près la largeur de la Méditerranée, <br>de Gibraltar à Chypre. <br>(Diametre de la Terre : 12 750km)
    </p>
    <h4>La distance de la Terre à la Lune : 400 000km</h4>
    <p>
        La Terre est à 150 000 000km de du soleil <br>Elle parcourt 1 milliard de km par an <br>pour en faire le tour. <br><br>Pour ref : <br>La taille de la Voie Lactée : <br>500 000 000 000 000 000 km
    </p>
</div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.englobe{
    overflow: scroll;
    margin-top: 10vh;
    margin-bottom: 15vh;
}
.katsankat {
    margin:auto;
    max-width: 400px;
}
</style>