<template>
  <div id="calendrier" class="lock-screen">
    <div class="container">
      <div
        class="axisBar"
        v-resize="onResize"
        :width="THEwidth"
        @click="clickMonth"
        v-ripple
      >
        <svg class="axis" width="100%" height="60px" >
          <!-- <rect width="100%" height="60px" fill="lightblue" /> -->
          <g id="axe" transform="translate(16,59)" :color="$vuetify.theme.dark ? 'white' : '#383840'"/>
          <g id="legend" transform="translate(16,59)" :color="$vuetify.theme.dark ? 'white' : '#383840'"/>
          <g>
            <text class="month" x="50%" y="50%" :fill="$vuetify.theme.dark ? 'white' : '#383840'">
              {{
                theMonth
                  .toLocaleString(undefined, {
                    month: "long",
                    year: "numeric"
                  })
                  .toUpperCase()
              }}
            </text>
          </g>
        </svg>
      </div>
      <div class="itemList">
        <v-virtual-scroll
          :bench="10"
          :items="listOfDays"
          item-height="38"
          id="listContainer"
        >
          <template v-slot:default="{ item }" class="item">
            <nuitCalItem
               v-ripple
              :latitude="latitude"
              :longitude="longitude"
              :width="THEwidth"
              :date="item"
              :id="date"
              :key="getKey(listOfDays, date)"
              @sendMonth="onMonthChange"
              @click.native="clickItem(item)"
            />
          </template>
        </v-virtual-scroll>
      </div>
      <!-- <div class="itemList" id="listContainer" @scroll="updateListOfDays">
        <nuitCalItem
          :width="dawidth"
          class="item"
          v-for="date in listOfDays"
          :id="date"
          :date="date"
          :key="getKey(listOfDays, date)"
          @sendMonth="onMonthChange"
          @click="clickItem(item)"
        />
      </div> -->
    </div>
    <v-btn class="floating">
      yo
    </v-btn>
  </div>
</template>

<script>
import nuitCalItem from "@/components/nuitCalItem.vue";
import * as d3 from "d3";

export default {
  name: "nuitCal",

  components: {
    nuitCalItem,
  },

  data: () => ({
    dawidth: window.innerWidth < 800 ? window.innerWidth : 800,
    componentsKey: 0,
    daysBefore: 7 * 2,
    theMonth: new Date(),
  }),

  computed: {
    latitude() {
      return this.$store.getters.lat;
    },
    longitude() {
      return this.$store.getters.long;
    },
    THEwidth() {
      return this.dawidth < 800 ? this.dawidth : 800;
    },

    date() {
      return d3.timeDay(this.$store.state.date);
    },

    nbofday() {
      return this.$store.state.nbofday;
    },
    listOfDays() {
      return d3.timeDay.range(
        // d3.timeDay.offset(d3.timeDay(new Date()), -this.daysBefore),
        // d3.timeDay.offset(d3.timeDay(new Date()), this.nbofday)

        d3.timeDay.offset(this.date, -this.daysBefore),
        d3.timeDay.offset(this.date, this.nbofday)
      );
    },
  },

  watch: {
    date: function() {
      this.scrollTo();
    },
  },

  methods: {
    // updateListOfDays(){
    //   this.listOfDays = d3.timeDay.range(
    //     d3.timeDay.offset(this.date, -this.daysBefore),
    //     d3.timeDay.offset(this.date, this.nbofday)
    //   );
    // },
    clickMonth() {
      var whereAreWe = document.getElementById("listContainer").scrollTop;
      console.log(this.date.getTime() == d3.timeDay(new Date()).getTime());
      if (this.date.getTime() === d3.timeDay(new Date()).getTime()) {
        if (whereAreWe != 496){
          console.log(whereAreWe)
          this.scrollTo()
        } else {this.$emit("showCalendarModal");}
      }
      this.$store.commit("JOUR_RESET");
    },

    onResize() {
      this.dawidth = window.innerWidth < 800 ? window.innerWidth : 800;
      this.componentsKey += 1;
      this.generateAxis();
    },

    getKey(listOfDays, date) {
      return this.componentsKey + listOfDays.indexOf(date);
    },

    clickItem(date) {
      // if(date==this.date){
      //   this.$store.commit("JOUR_SET", date);
      //   // this.updateListOfDays();
      //   this.$router.push("/");
      // } else {
      this.$store.commit("JOUR_SET", date);
      this.$router.push("/");
      // }
      // this.$router.push("/");
    },

    onMonthChange(value) {
      this.theMonth = value;
      if (value.getMonth() === this.theMonth.getMonth()) {
        return;
      }
      console.log("yo");
      this.theMonth = value;
    },
    generateAxis() {
      const time = d3.timeDay(new Date());
      const width = this.THEwidth - 16 * 2 - 1;
      const axe = d3.select("#axe");
      const legend = d3.select("#legend");
      const xT = d3
        .scaleTime()
        .domain([d3.timeHour.offset(time, 12), d3.timeHour.offset(time, 36)])
        .range([0, width])
        .nice();
      const axis = d3
        .axisTop(xT)
        .ticks(d3.timeHour.every(1), "")
        .tickSize(3);
      const legendAxis = d3
        .axisTop(xT)
        .ticks(d3.timeHour.every(4), "%Hh")
        // .tickFormat(x=>x.getHours()===12 ? "" : d3.timeFormat(x,"%Hh"))
        .tickSize(4);
      axe.call(axis);
      legend.call(legendAxis);
      // .selectAll(".tick line")
      // .attr("stroke-width", "1.5");
    },
    scrollTo() {
      this.$vuetify.goTo(60 - 2 + (this.daysBefore - 1) * 38, {
        container: "#listContainer",
      });
    },
  },

  mounted: function() {
    this.generateAxis();
    this.theMonth = this.date;
    this.scrollTo();
  },
};
</script>

<style scoped>
* ::-webkit-scrollbar {display:none;}

* {
  scrollbar-width: none; /* Firefox hide scrollbar*/
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select:none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

   scrollbar-width: none;
}

.month {
  font-size: medium;
  text-anchor: middle;
}

.extend {
  padding: 5px;
}

.axisBar {
  height: 60px;
}

.axisBar:hover {
  cursor: pointer;
}

.itemList {
  /* display: grid; */
  /* margin-top: 60px; */
  scroll-snap-type: y mandatory;
  height: 90vh;
  overflow-y: scroll;
}

/* .item { */
/* scroll-snap-align: start; */
/* display: flex; */
/* height:10vh */
/* } */

.lock-screen {
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: fixed;
  padding-bottom: 112px;
  /* touch-action: pan-y; */
}

.container {
  max-width: 800px;
  margin: auto;
  padding: 0;
}

.floating {
  position: absolute;
  margin: auto;
}
</style>
