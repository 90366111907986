var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lock-screen"},[_c('div',{staticClass:"content",staticStyle:{"max-width":"900px"}},[_c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"parent"},[_c('div',{staticClass:"two-canvas-container"},[_c('canvas',{attrs:{"id":"background-canvas"}}),_c('canvas',{attrs:{"id":"projection-canvas"},on:{"touchstart":_vm.touchstart,"touchmove":_vm.touchmove,"touchend":_vm.touchend,"mousedown":_vm.dragstart,"mousemove":_vm.drag,"mouseup":_vm.dragend}}),_c('v-btn',{style:({
            backgroundColor: _vm.colors.fond,
            marginLeft: _vm.ctxWidth - 48 + 'px',
            marginTop: 8 + 'px',
          }),attrs:{"fab":"","outlined":"","small":""},on:{"click":_vm.zoom}},[_c('v-icon',[_vm._v(_vm._s(_vm.zoomState ? "mdi-magnify-minus-outline" : "mdi-magnify-plus-outline"))])],1),(_vm.isIOS)?_c('v-btn',{style:({
            backgroundColor: _vm.colors.fond,
            marginLeft: _vm.ctxWidth - 48 + 'px',
            marginTop: 8 + 'px',
          }),attrs:{"color":"error","fab":"","outlined":"","small":""},on:{"click":_vm.startDeviceOrientation}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.deviceOrientationActive ? "mdi-navigation" : "mdi-navigation-outline")+" ")])],1):_vm._e(),(_vm.turnAroundActive)?_c('v-btn',{staticClass:"turnAroundButtun",style:({
            marginTop: (_vm.ctxHeight * 1) / 2 + 'px',
          }),attrs:{"color":"red","fab":"","outlined":"","small":""},on:{"click":_vm.turnAround}},[_c('v-icon',[_vm._v(" mdi-rotate-3d-variant ")])],1):_vm._e()],1),_c('div',{staticClass:"bottom-nav"},[_c('timeWheel',{attrs:{"height":50,"width":_vm.ctxWidth,"maxWidth":_vm.ctxHeight < 600 ? 780 : 1000}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }