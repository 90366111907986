import Vue from "vue";
import Vuex from "vuex";
const tzlookup = require("tz-lookup");

Vue.use(Vuex);
const getDefaultState = () => {
  return {
    cityPick: 0,
    cities: [
      {
        label: "Paris",
        lat: 48.86,
        long: 2.33,
        lock: true,
      },
      {
        label: "Avignon",
        lat: 43.83,
        long: 5.21,
        lock: true,
      },
      {
        label: "Embrun",
        lat: 44.56,
        long: 6.42,
        lock: true,
      },
    ],
    date: new Date(),
    // offsetDate: new Date(),
    startDate: new Date(),
    minuteToOffset: 0,
    nbofday: 364,

    options: {
      global: {
        thedark: false,
        autodark: true,
        defaultHomePage: 2, // 0 is calendar, 1 is one, 2 is compass
      },
      one: {
        showAltitude: false,
        defaultScrollTo: 0 // 0 is sunset, 1 is sunrise, 2 is now
      },
      cal: {
        douzeVintquatre: false,
        midnightfocus: true,
      },
      compass: {
        autoNight: true,
        compassPreview: false,
        stars: { show: true, label: false, variation: 0 },
        planets: { show: true, label: false, variation: 0 },
        constellations: { show: true, label: false, variation: 1 },
        sunTr: { show: true },
        moonTr: { show: true },
        graticule: { show: true, label: true, variation: 1 },
        preselect: 4, // 0 is S, 1 is C, 2 is none, /* 3 is all */, 4 is perso
      }
    },

    optionWatcher: 0,
    petitesOptions: false,
    plusDeChoix: false,

    landScapeMode: false,
  }
}

export default new Vuex.Store({
  state: getDefaultState(),

  getters: {
    lat: (state) => {
      return Number(state.cities[state.cityPick].lat);
    },
    long: (state) => {
      return Number(state.cities[state.cityPick].long);
    },
    tz: (state) => {
      return tzlookup(state.cities[state.cityPick].lat, state.cities[state.cityPick].long);
    },
    nbOfStars: (state) => {
      switch (state.options.compass.stars.variation) {
        case 2:
          return 5000;
        case 1:
          return 1200;
        case 0:
          return 500;
        default: 
        return 1200;
      } 
    },
    
    maxRankForConstelLabel: (state) => {
      switch (state.options.compass.constellations.variation) {
        case 2:
          return 3;
        case 1:
          return 2;
        case 0:
          return 1;
        default: 
        return 1;
      } 
    },

  },

  mutations: {
    resetOptions (state) {
      Object.assign(state, getDefaultState())
      // Check if the ID exists
      if (localStorage.getItem("cities")) {
        // Replace the state object with the stored item
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem("cities")))
        );
      }
    },
    resetCities (state) {
      Object.assign(state, getDefaultState())
      // Check if the ID exists
      if (localStorage.getItem("options")) {
        // Replace the state object with the stored item
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem("options")))
        );
      }
    },
    initialiseStore(state) {
      // Check if the ID exists
      if (localStorage.getItem("cities")) {
        // Replace the state object with the stored item
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem("cities")))
        );
      }
      // Check if the ID exists
      if (localStorage.getItem("options")) {
        // Replace the state object with the stored item
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem("options")))
        );
      }
    },
    JOUR_SUIVANT(state, payload) {
      var next = new Date(
        state.date.setDate(state.date.getDate() + Number(payload))
      );
      state.date = next;
    },
    JOUR_PRECEDENT(state, payload) {
      var next = new Date(
        state.date.setDate(state.date.getDate() - Number(payload))
      );
      state.date = next;
    },
    JOUR_RESET(state) {
      var next = new Date();
      state.date = next;
    },
    JOUR_SET(state, date) {
      state.date = date;
    },
    LAT_SET(state, data) {
      state.lat = data;
    },
    LONG_SET(state, data) {
      state.long = data;
    },
    CITY_SET(state, index) {
      state.cityPick = index;
    },
    CITY_ADD(state, newCity) {
      state.cities.unshift({
        label: newCity.addLabel,
        lat: newCity.addLat,
        long: newCity.addLong,
        lock: true,
      });
      state.cityPick = 0;
    },
    CITY_MOOVE_UP(state, index) {
      var selectedCity = state.cities[state.cityPick];
      var cityToMoove = state.cities.splice(index, 1)[0];
      state.cities.splice(index - 1, 0, cityToMoove);
      state.cityPick = state.cities.indexOf(selectedCity);
    },
    CITY_MOOVE_DOWN(state, index) {
      var selectedCity = state.cities[state.cityPick];
      var cityToMoove = state.cities.splice(index, 1)[0];
      state.cities.splice(index + 1, 0, cityToMoove);
      state.cityPick = state.cities.indexOf(selectedCity);
    },
    CITY_DELETE(state, index) {
      state.cities.splice(index, 1);
    },
    CITY_UPDATE(state, payload) {
      state.cities[payload.index] = payload.update;
    },
    THEME_UPDATE(state, payload) {
      state.options.global.thedark = payload;
    },
    AUTOTHEME_UPDATE(state, payload) {
      state.options.global.autodark = payload;
    },
    DEFAULTHOME_UPDATE(state, payload) {
      state.options.global.defaultHomePage = payload;
    },
    SHOWALTI_UPDATE(state, payload) {
      state.options.one.showAltitude = payload;
    },
    // OFFSETDATE_UPDATE(state, payload) {
    //   state.offsetDate = payload;
    // },
    STARTDATE_UPDATE(state, payload) {
      state.startDate = payload;
    },
    MINUTETOOFFSET_UPDATE(state, payload) {
      state.minuteToOffset = payload;
    },
    TOGGLE_ALL_TXT(state) {
      state.options.compass.preselect >= 2 //change if more preselect
        ? state.options.compass.preselect = 0
        : state.options.compass.preselect += 1;

      switch (state.options.compass.preselect) {
        case 0: // S
          state.options.compass.constellations.label = false;
          state.options.compass.stars.label = true;
          state.options.compass.stars.show = true;
          state.options.compass.planets.label = true;
          state.optionWatcher += 1;
          break;
        case 1: // C
          state.options.compass.constellations.label = true;
          state.options.compass.constellations.show = true;
          state.options.compass.stars.label = false;
          state.options.compass.stars.show = true;
          state.options.compass.planets.label = false;
          state.optionWatcher += 1;
          break;
          case 2: // none
            state.options.compass.constellations.label = false;
            state.options.compass.constellations.show = false;
            state.options.compass.stars.label = false;
            state.options.compass.planets.label = false;
            state.optionWatcher += 1;
            break;
        // case 3: // all
        //   state.options.compass.constellations.label = true;
        //   state.options.compass.constellations.show = true;
        //   state.options.compass.stars.label = true;
        //   state.options.compass.stars.show = true;
        //   state.options.compass.planets.label = true;
        //   state.optionWatcher += 1;
        //   break;
      }
    },
    OPTION_TOGGLE(state, payload) {
      state.options.compass[payload.object][payload.option] = !state.options.compass[payload.object][payload.option];
      state.optionWatcher += 1;
      state.options.compass.preselect = 4;
    },
    OPTION_TRUE(state, payload) {
      state.options.compass[payload.object][payload.option] = true;
      state.optionWatcher += 1;
      state.options.compass.preselect = 4;
    },
    VARIATION_TOGGLE(state, payload) {
      state.options.compass[payload.object].variation = payload.newVariation;
      state.optionWatcher += 1;
    },
    PETITES_OPTIONS_TOGGLE(state, payload) {
      state.petitesOptions = payload
    },
    PLUSDECHOIX_TOGGLE(state, payload) {
      state.plusDeChoix = payload
    },
    LANDESCAPEMODE_TOGGLE(state, payload) {
      state.landScapeMode = payload
    },
    AUTONIGHT_TOGGLE(state, payload){
      state.options.compass.autoNight = payload
      state.optionWatcher += 1;
    },
    COMPASSPREVIEW_TOGGLE(state, payload){
      state.options.compass.compassPreview = payload
      state.optionWatcher += 1;
    },
  },

  actions: {
    resetOptions ({ commit }) {
      commit('resetOptions')
    },
    resetCities ({ commit }) {
      commit('resetCities')
    },
  },

  modules: {},
});
