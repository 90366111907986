<template>
  <div v-resize="onResize">
    <div class="pa-1" >
      <v-row justify="space-around" align="center" dense >
        <v-btn icon @click="showOptions()" color="secondary">
          <v-icon dense>
            mdi-eye-settings-outline
          </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn icon @click="prevDay(1)">
          <v-icon dense color="secondary">
            mdi-chevron-left
          </v-icon>
        </v-btn>

        <v-btn class="date" color="secondary" small text @click="resetDay()">
          {{ titleDay }}
          <v-icon class="mx-2" dense color="error" v-if="!closeToNow">
            mdi-history
          </v-icon>
          {{ titleHour }}
        </v-btn>

        <v-btn icon @click="nextDay(1)">
          <v-icon dense color="secondary">
            mdi-chevron-right
          </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn icon @click="toggleText()" color="secondary">
          <v-icon dense>
            {{ preselectIcon }}
          </v-icon>
        </v-btn>
      </v-row>
    </div>
    <div class="wheelparent" :width="width" :height="height">
      <div
        class="overlap"
        :class="$vuetify.theme.dark ? 'overdark' : 'overlight'"
      >
        <svg viewBox="0,0,8,8" style="width: 8px; height: 8px;">
          <path d="M 0 0 H 8 L 4 8" fill="red" />
        </svg>
      </div>
      <div
        class="containerOdWheel"
        :width="width"
        :height="height"
        id="wheelContainer"
        @scroll="onScroll"
        @mousedown="dragstart"
        @mousemove="drag"
        @mouseup="dragend"
        @mouseleave="dragleave"
      >
        <!-- 
        @wheel="cancelMomentumTracking" -->
        <svg class="overflow" :width="overwidthwheel">
          <g id="wheel-svg">
            <line
              v-for="tick in timeWheelTicks"
              :key="tick.x1"
              :x1="tick.x1"
              :x2="tick.x2"
              :y1="tick.y1"
              :y2="tick.y2"
              :stroke="$vuetify.theme.dark ? 'white' : 'black'"
              :stroke-width="tick.width"
            />
            <text
              v-for="text in timeWheelText"
              :key="text.id"
              :x="text.x"
              :y="text.y"
              font-size="10"
              dy="0.6em"
              :text-anchor="text.pos"
              :fill="$vuetify.theme.dark ? 'white' : 'black'"
            >
              {{ text.text }}
            </text>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  name: "timeWheel",
  props: {
    width: {
      required: true,
      type: Number,
    },
    height: {
      required: true,
      type: Number,
    },
    maxWidth: {
      required: true,
      type: Number,
    },
  },
  data: () => ({



    dawidth: window.innerWidth,
    // WHEEL
    nbJourForward: 7,
    nbJourBackward: 2,
    pxPerMinute: 1,

    minuteToOffset: 0,

    //DRAG VARIABLES
    isDown: false,
    startX: 0,
    scrollLeft: 0,
    velX: 0,
    momentumID: "",

    //Options
    textOn: false,
  }),

  mounted: function() {
    this.dawidth = window.innerWidth < 900 ? window.innerWidth : 900;
    //  < this.maxWidth
    //     ? window.innerWidth
    //     : this.maxWidth;
    this.scrollToDaDate();

    let opt = this.compassoptions;
    if (
      opt.constellations.label ||
      opt.constellations.show ||
      opt.stars.label ||
      opt.planets.label
    ) {
      this.textOn = true;
    }

    this.$store.commit("STARTDATE_UPDATE", this.startDate);
    this.$store.commit("MINUTETOOFFSET_UPDATE", this.minuteToOffset);
  },

  watch: {
    startDate: function() {
      this.$store.commit("STARTDATE_UPDATE", this.startDate);
    },
    minuteToOffset: function() {
      this.$store.commit("MINUTETOOFFSET_UPDATE", this.minuteToOffset);
    },
  },

  // ___________________/\\\\\\\\\____________
  // _________________/\\\////////_____________
  // ________________/\\\/______________________
  // ________________/\\\________________________
  // ________________\/\\\________________________
  // _________________\//\\\_______________________
  // ___________________\///\\\_____________________
  // ______________________\////\\\\\\\\\____________
  // __________________________\/////////_____________

  computed: {
    date() {
      return d3.timeDay(this.$store.state.date);
    },
    compassoptions() {
      return this.$store.state.options.compass;
    },
    optionWatcher() {
      return this.$store.state.optionWatcher;
    },
    closeToNow(){ // FIIIIIIIIIIIIIIIIIIIIIIIIIIIIX VIA OBSERVABLE FIIIIIIIIIIIIIIIIIIIIIx
      let d1=new Date(), d2=this.offsetDate;
      return d1.getFullYear() === d2.getFullYear() &&
              d1.getDate() === d2.getDate() &&
              d1.getMonth() === d2.getMonth() &&
              d1.getHours() === d2.getHours() &&
              d1.getMinutes() === d2.getMinutes();
    },
    preselectIcon() {
      let select = this.compassoptions.preselect;
      let result = "";
      switch (select) {
        case 0:
          result = "mdi-star-four-points-outline";
          break;
        case 1:
          result = "mdi-vector-polyline";
          break;
        case 2:
          result = "mdi-label-off-outline";
          break;
        // case 3 : result = 'mdi-alphabetical-variant'; break;
        case 4:
          result = "mdi-bookmark-multiple-outline";
          break;
        // case 0 : result = 'mdi-alpha-s'; break;
        // case 1 : result = 'mdi-alpha-c'; break;
        // case 2 : result = 'mdi-alphabetical-variant-off'; break;
        // // case 3 : result = 'mdi-alphabetical-variant'; break;
        // case 4 : result = 'mdi-bookmark-multiple-outline'; break;
      }
      return result;
    },

    //If it's today, time is now, if not, time is noon on that day
    daDate() {
      return this.date.getTime() === d3.timeDay(new Date()).getTime()
        ? new Date()
        : d3.timeHour.offset(this.date, 12);
    },

    decalage() {
      return (this.dawidth - 16 * 2) / 2;
    },
    offsetDate() {
      return d3.timeMinute.offset(this.startDate, this.minuteToOffset);
    },

    titleDay() {
      return this.offsetDate.toLocaleString(undefined, {
        // weekday: "short",
        day: "numeric",
        month: "short",
      });
      // .toUpperCase();
    },
    titleHour() {
      return this.offsetDate.toLocaleString(undefined, {
        hour: "numeric",
        minute: "numeric",
      });
    },
    startDate() {
      return d3.timeDay.offset(this.date, -this.nbJourBackward);
    },

    //WHEEEEEEEL RANGE
    overwidthwheel() {
      return (
        (this.nbJourForward + this.nbJourBackward) * 24 * 60 * this.pxPerMinute
      );
    },
    timeWheelTicks() {
      let result = [];
      for (
        let i = 0;
        i < this.overwidthwheel + 1;
        i = i + 10 * this.pxPerMinute
      ) {
        let isItADay = i % (60 * 24 * this.pxPerMinute) == 0;
        result.push({
          x1: i - 0.5,
          y1: 10,
          x2: i - 0.5,
          y2: isItADay ? 26 : i % (60 * this.pxPerMinute) == 0 ? 20 : 15,
          width: 1,
        });
        if (isItADay) {
          result.push({
            x1: i - 5,
            y1: 26.5,
            x2: i + 5,
            y2: 26.5,
            width: 1,
          });
        }
      }
      return result;
    },
    timeWheelText() {
      let result = [];
      for (
        let i = 0;
        i < this.overwidthwheel + 1;
        i = i + 120 * this.pxPerMinute
      ) {
        let time = new Date(
          this.startDate.getTime() + (i / this.pxPerMinute) * 60 * 1000
        );
        let isItADay = i % (60 * 24 * this.pxPerMinute) == 0;
        result.push({
          x: i,
          y: isItADay ? 35 : 25,
          text:
            i % (60 * 24 * this.pxPerMinute) == 0
              ? time.toLocaleString(undefined, {
                  weekday: "short",
                  day: "numeric",
                  month: "long",
                })
              : time.getHours() + ":00",
          pos: "middle",
        });
      }
      return result;
    },
  },

  // _____________/\\\\____________/\\\\____________
  // _____________\/\\\\\\________/\\\\\\____________
  // ______________\/\\\//\\\____/\\\//\\\____________
  // _______________\/\\\\///\\\/\\\/_\/\\\____________
  // ________________\/\\\__\///\\\/___\/\\\____________
  // _________________\/\\\____\///_____\/\\\____________
  // __________________\/\\\_____________\/\\\____________
  // ___________________\/\\\_____________\/\\\____________
  // ____________________\///______________\///_____________

  methods: {
    scrollToDaDate() {
      var scrollOffsetDeBase =
        ((this.daDate.getTime() - this.startDate.getTime()) / (60 * 1000)) *
          this.pxPerMinute -
        this.decalage;
      document
        .getElementById("wheelContainer")
        .scrollTo(scrollOffsetDeBase, { duration: 0 });
    },

    onScroll(e) {
      var daminute = (e.target.scrollLeft + this.decalage) / this.pxPerMinute;
      this.minuteToOffset = daminute;
      // this.$store.commit("OFFSETDATE_UPDATE", this.offsetDate);
    },
    nextDay(n) {
      this.$store.commit("JOUR_SUIVANT", n);
      // this.$store.commit("OFFSETDATE_UPDATE", this.offsetDate);
    },
    prevDay(n) {
      this.$store.commit("JOUR_PRECEDENT", n);
      // this.$store.commit("OFFSETDATE_UPDATE", this.offsetDate);
    },
    resetDay() {
      let timeLine = document.getElementById("wheelContainer");

      //stop rAF from drag momentum
      this.cancelMomentumTracking();
      document.getElementById("wheelContainer").classList.add("going");
      // stop touch scroll momentum
      timeLine.style.overflow = "hidden";
      setTimeout(function() {
        timeLine.style.overflow = "";
      }, 10);

      this.$store.commit("JOUR_RESET");
      // this.$store.commit("OFFSETDATE_UPDATE", this.offsetDate);
      this.scrollToDaDate();
      document.getElementById("wheelContainer").classList.remove("going");
    },

    toggleText() {
      this.$store.commit("TOGGLE_ALL_TXT");
    },

    showOptions() {
      this.$store.commit("PETITES_OPTIONS_TOGGLE", true);
      this.$store.commit("PLUSDECHOIX_TOGGLE", false);
      this.$parent.$emit("showOptionsModal");
    },

    onResize() {
      this.dawidth = window.innerWidth < 900 ? window.innerWidth : 900;
      this.scrollToDaDate();
    },

    // DRAG TIMEWHEEEl  <----------------------  DRAG TIMEWHEEEl
    //     ____     ____         ___     ______
    //    |    \   |    \       /   |   /      \
    //    |     \  |     \     /    |  |        \
    //    |     |  |     |    /     |  |
    //    |     |  |____/    /______|  |     ____
    //    |    /   |    \   /       |  |        /
    //    |___/    |     \ /        |   \______/
    //
    // DRAG TIMEWHEEEl  <---------------------  DRAG TIMEWHEEEl
    dragstart(e) {
      const slider = document.getElementById("wheelContainer");
      this.isDown = true;
      slider.classList.add("active");
      this.startX = e.pageX - slider.offsetLeft;
      this.scrollLeft = slider.scrollLeft;
      this.cancelMomentumTracking();
      this.velX = 0;
    },
    dragend() {
      const slider = document.getElementById("wheelContainer");
      this.isDown = false;
      slider.classList.remove("active");
      this.beginMomentumTracking();
    },

    dragleave() {
      const slider = document.getElementById("wheelContainer");
      this.isDown = false;
      slider.classList.remove("active");
    },
    drag(e) {
      if (!this.isDown) return;
      // console.log(e.pageX)
      const slider = document.getElementById("wheelContainer");
      e.preventDefault();
      const x = e.clientX - slider.offsetLeft;
      const walk = (x - this.startX) * 1.5; //scroll-normal
      var prevScrollLeft = slider.scrollLeft;
      slider.scrollLeft = this.scrollLeft - walk;
      this.velX = slider.scrollLeft - prevScrollLeft;
    },

    // Momentum for drag
    beginMomentumTracking() {
      this.cancelMomentumTracking();
      this.momentumID = requestAnimationFrame(this.momentumLoop);
    },
    cancelMomentumTracking() {
      cancelAnimationFrame(this.momentumID);
    },
    momentumLoop() {
      const slider = document.getElementById("wheelContainer");
      slider.scrollLeft += this.velX;
      this.velX *= 0.98;
      if (Math.abs(this.velX) > 0.5) {
        this.momentumID = requestAnimationFrame(this.momentumLoop);
      }
    },
  },
};
</script>

<style scoped>
* {
  scrollbar-width: none; /* Firefox hide scrollbar*/
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.wheelparent {
  position: relative;
  margin: 0;
  padding: 0;
}

.containerOdWheel {
  /* position: absolute; */
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  cursor: grab;
  cursor: -webkit-grab;
  scroll-behavior: unset;
}
.containerOdWheel.active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}
.containerOdWheel.going {
  scroll-behavior: smooth;
}

.overlap {
  position: absolute;
  height: 44px;
  width: 102%;
  margin-right: -1px;
  margin-left: -1px;
  /* margin: 0px -10px 0px -10px; */
  /* border: 1px solid red; */
  display: grid;
  justify-items: center;
  touch-action: none;
  pointer-events: none;
  /* max-width: 900px; */
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 205;
}

.overdark {
  background: linear-gradient(
    90deg,
    rgba(18, 18, 18, 1) 0%,
    rgba(18, 18, 18, 1) 7%,
    rgba(18, 18, 18, 0) 40%,
    rgba(18, 18, 18, 0) 60%,
    rgba(18, 18, 18, 1) 93%,
    rgba(18, 18, 18, 1) 100%
  );
}
.overlight {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 7%,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, 0) 60%,
    rgba(255, 255, 255, 1) 93%,
    rgba(255, 255, 255, 1) 100%
  );
}

.container::-webkit-scrollbar {
  display: none;
}

.overflow {
  display: block;
}

.date {
  /* color: #666; */
  width: 50%;
  /* min-width: 200px; */
  max-width: 250px;
  font-size: 0.9em;
  /* max-height: 24px; */
}
</style>
