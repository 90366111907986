<template>
  <v-app>
    <v-app-bar fixed color="transparent" flat app height="56">
      <!-- LEFT APP BAR -->
      <!-- LEFT APP BAR -->
      <!-- LEFT APP BAR -->
      <!-- LEFT APP BAR -->
      <!-- LEFT APP BAR -->
      <!-- LEFT APP BAR -->
      <!-- LEFT APP BAR -->
      <!-- LEFT APP BAR -->
      <!-- LEFT APP BAR -->
      <!-- LEFT APP BAR -->
      <!-- LEFT APP BAR -->

      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
      <v-btn icon @click="homeButton">
        <!-- <v-icon>mdi-circle-half-full</v-icon> -->
        <v-icon v-if="this.$router.currentRoute.name === 'Cities' || this.$router.currentRoute.name === 'Infos'">mdi-arrow-left</v-icon>
        <svg
          v-if="this.$router.currentRoute.name != 'Cities' && this.$router.currentRoute.name != 'Infos'"
          viewBox="0,0,28,28"
          style="width: 28px; height: 28px;"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <circle
            cx="14"
            cy="14"
            r="13.5"
            :fill="$vuetify.theme.dark ? 'white' : '#383840'"
          />
          <path
            transform="translate(2,2) rotate(210,12,12)"
            :fill="!$vuetify.theme.dark ? 'white' : '#121212'"
          >
            <animate
              id="animation-to-check"
              repeatCount="indefinite"
              attributeName="d"
              dur="40s"
              attributeType="auto"
              values="
    M 12 0 
    C 5 0, 0 5, 0 12 
    C 0 19, 5 24, 12 24 
    C 5 24, 0 19, 0 12 
    C 0 5, 5 0, 12 0
    Z;


    M 12 0 
    C 5 0, 0 5, 0 12 
    C 0 19, 5 24, 12 24 
    C 19 24, 24 19, 24 12 
    C 24 5, 19 0, 12 0
    Z;


    M 12 0 
    C 19 0, 24 5 , 24 12 
    C 24 19, 19 24, 12 24 
    C 19 24, 24 19, 24 12 
    C 24 5, 19 0, 12 0
    Z;"
            />
          </path>
        </svg>
      </v-btn>
      <v-toolbar-title @click="gpsModal = true" v-if="this.$router.currentRoute.name != 'Infos'">
        <a>{{ cities[cityPick].label }}</a>
      </v-toolbar-title>
      <v-toolbar-title v-if="this.$router.currentRoute.name === 'Infos'">
        <a> Infos </a>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- RIGHT APP BAR -->
      <!-- RIGHT APP BAR -->
      <!-- RIGHT APP BAR -->
      <!-- RIGHT APP BAR -->
      <!-- RIGHT APP BAR -->
      <!-- RIGHT APP BAR -->
      <!-- RIGHT APP BAR -->
      <!-- RIGHT APP BAR -->
      <!-- RIGHT APP BAR -->
      <!-- RIGHT APP BAR -->
      <!-- RIGHT APP BAR -->
      <!-- RIGHT APP BAR -->
      <!-- RIGHT APP BAR -->
      <!-- RIGHT APP BAR -->

      <v-dialog width="290" v-model="gpsModal">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="red lighten-2" icon dark v-bind="attrs" v-on="on">
            <v-icon>mdi-map-marker</v-icon>
          </v-btn>
        </template>

        <!-- LOCATION MODAL -->
        <!-- LOCATION MODAL -->
        <!-- LOCATION MODAL -->
        <!-- LOCATION MODAL -->
        <!-- LOCATION MODAL -->
        <!-- LOCATION MODAL -->
        <!-- LOCATION MODAL -->
        <!-- LOCATION MODAL -->
        <!-- LOCATION MODAL -->
        <!-- LOCATION MODAL -->

        <v-card>
          <v-card-title class="text-center">
            LIEUX
          </v-card-title>
          <v-col>
            <v-radio-group v-model="citySelect">
              <v-radio
                v-for="city in cities"
                :key="city.id"
                :label="city.label"
                :value="city.id"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-divider></v-divider>
          <v-btn color="primary" text @click="addCity">
            <v-icon left>
              mdi-map-marker-plus
            </v-icon>
            AJOUTER / MODIFIER
          </v-btn>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="gpsModal = false">
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- CALENDAR MODAL -->
      <!-- CALENDAR MODAL -->
      <!-- CALENDAR MODAL -->
      <!-- CALENDAR MODAL -->
      <!-- CALENDAR MODAL -->
      <!-- CALENDAR MODAL -->
      <!-- CALENDAR MODAL -->
      <!-- CALENDAR MODAL -->
      <!-- CALENDAR MODAL -->
      <!-- CALENDAR MODAL -->
      <!-- CALENDAR MODAL -->
      <!-- CALENDAR MODAL -->

      <v-dialog width="290" v-model="calendarModal">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-calendar-search</v-icon>
          </v-btn>
        </template>
        <v-date-picker
          :header-color="$vuetify.theme.dark ? '' : '#333'"
          full-width
          scrollable
          v-model="picker"
          locale="fr-fr"
          first-day-of-week="1"
        >
          <v-btn text color="red lighten-2" @click="changeDateToToday">
            Today
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="secondary" @click="calendarModal = false">
            Cancel
          </v-btn>
          <v-btn text @click="changeDate">
            OK
          </v-btn></v-date-picker
        >
      </v-dialog>

      <!-- <v-dialog width="300" v-model="optionModal">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-center">
            OPTIONS
          </v-card-title>
          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-switch v-model="autodarkSelect"> </v-switch>
              </v-list-item-action>
              <v-list-item-title>Dark Mode Auto</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-btn color="primary" text>
            Autres options
          </v-btn>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="gpsModal = false">
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->

      <!-- MENU -->
      <!-- MENU -->
      <!-- MENU -->
      <!-- MENU -->
      <!-- MENU -->
      <!-- MENU -->
      <!-- MENU -->
      <!-- MENU -->
      <!-- MENU -->
      <!-- MENU -->
      <!-- MENU -->
      <!-- MENU -->

      <v-menu bottom left offset-y close-on-click>
        <!--  :close-on-content-click="false" -->
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-card class="text-left">
          <!-- AUTO DARK -->
          <v-list class="mx-auto">
            <!-- DARK MODE ON/OFF -->
            <v-list-item link @click="goDark">
              <v-list-item-title>Dark Mode</v-list-item-title>
              <v-list-item-icon>
                <v-icon>
                  mdi-lightbulb-outline
                </v-icon>
              </v-list-item-icon>
            </v-list-item>

            <!-- OPTIONS -->
            <v-list-item link @click="goOptions">
              <v-list-item-title left>Options</v-list-item-title>
              <v-list-item-icon>
                <v-icon>
                  mdi-tune
                </v-icon>
              </v-list-item-icon>
            </v-list-item>

            <!-- mes villes -->
            <v-list-item link @click="addCity">
              <v-list-item-title>Mes villes</v-list-item-title>
              <v-list-item-icon>
                <v-icon>
                  mdi-map-marker-multiple-outline
                </v-icon>
              </v-list-item-icon>
            </v-list-item>

            <!-- à propos -->
            <v-list-item link to="/infos" push>
              <v-list-item-title>À propos</v-list-item-title>
              <v-icon>
                mdi-information-variant
              </v-icon>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

      <!-- OPTION MODAL -->
      <!-- OPTION MODAL -->
      <!-- OPTION MODAL -->

      <v-dialog v-model="optionModal" scrollable :content-class="petitesOptions ? 'option-dialog-down' : 'option-dialog'">
        <optionModal @stopOptions="optionModal = false"/>
      </v-dialog>
    </v-app-bar>

    <!-- MAIN -->
    <!-- MAIN -->
    <!-- MAIN -->
    <!-- MAIN -->
    <!-- MAIN -->
    <!-- MAIN -->
    <!-- MAIN -->
    <!-- MAIN -->
    <!-- MAIN -->
    <!-- MAIN -->
    <!-- MAIN -->

    <v-main>
      <!-- <keep-alive> -->
      <!-- <transition name="fade"> -->
      <router-view
        @showCalendarModal="calendarModal = true"
        @showOptionsModal="optionModal = true"
      />
      <!-- </transition> -->
      <!-- </keep-alive> -->
    </v-main>

    <!-- BOTTOM NAV -->
    <!-- BOTTOM NAV -->
    <!-- BOTTOM NAV -->
    <!-- BOTTOM NAV -->
    <!-- BOTTOM NAV -->
    <!-- BOTTOM NAV -->
    <!-- BOTTOM NAV -->
    <!-- BOTTOM NAV -->
    <!-- BOTTOM NAV -->
    <!-- BOTTOM NAV -->
    <!-- BOTTOM NAV -->
    <!-- BOTTOM NAV -->
    <!-- BOTTOM NAV -->

    <v-bottom-navigation
      color="primary"
      fixed
      app
      :background-color="$vuetify.theme.dark ? '#121212' : '#FFF'"
    >
      <v-btn to="/calendar" push height="100%">
        <!-- <span>Calendrier</span> -->

        <v-icon>mdi-calendar-blank-outline</v-icon>
      </v-btn>
      <v-btn to="/" push height="100%">
        <!-- <span>Nuit</span> -->

        <v-icon>mdi-timelapse</v-icon>
      </v-btn>

      <v-btn to="/compass" push height="100%">
        <!-- <span>Boussole</span> -->

        <v-icon>mdi-globe-model</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
  <!-- <div id="app">
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view/>
  </div> -->
</template>

<script>
import optionModal from "@/components/optionModal.vue";

export default {
  components: { optionModal },
  data() {
    return {
      gpsModal: false,
      calendarModal: false,
      optionModal: false,
      picker: new Date().toISOString().substr(0, 10),
      citySelect: 0,
    };
  },
  watch: {
    citySelect: function() {
      this.$store.commit("CITY_SET", this.citySelect);
      this.gpsModal = false;
    },
    cityPick: function() {
      this.citySelect = this.cityPick;
    },
    date: function() {
      this.picker = new Date(this.date).toISOString().substr(0, 10)
    },
    optionModal: function() {
      this.$store.commit("COMPASSPREVIEW_TOGGLE", (this.optionModal && this.petitesOptions))
    },
    petitesOptions: function() {
      this.$store.commit("COMPASSPREVIEW_TOGGLE", (this.optionModal && this.petitesOptions))
    },
    vuetifydark: function() {
      this.$store.commit("THEME_UPDATE", this.$vuetify.theme.dark);
    },
    thedark: function() {
      this.$vuetify.theme.dark = this.thedark
    }
  },
  beforeMount: function() {
    this.$store.commit("THEME_UPDATE", this.$vuetify.theme.dark);
    //local storage dark mode
    if (this.thedark) {
      this.$vuetify.theme.dark = true;
    }
    if (this.autodark) {
      //check device dark mode
      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        this.$vuetify.theme.dark = true;
      }

      //check if it's night time then go dark
      // if (new Date().getHours() >= 21 || new Date().getHours() <= 7) {
      //   this.$vuetify.theme.dark = true;
      // }
    }

    if(this.defaultHomePage == 2) {
        this.$router.push("/compass");
    }
    if(this.defaultHomePage == 0) {
        this.$router.push("/calendar");
    }
  },
  mounted: function() {
    this.citySelect = this.cityPick;
    const mql = window.matchMedia("(prefers-color-scheme: dark)");
    const dol = window.matchMedia("(orientation: landscape)");

    mql.addEventListener("change", () => {
      if (this.autodark) {
        this.$vuetify.theme.dark = window.matchMedia(
          "(prefers-color-scheme: dark)"
        ).matches;
      }
    });
    dol.addEventListener("change", () => {
      this.$store.commit("LANDESCAPEMODE_TOGGLE",window.matchMedia("(orientation: landscape)").matches)
      });

  },

  methods: {
    delay(ms) {
      const startPoint = new Date().getTime();
      while (new Date().getTime() - startPoint <= ms) {
        /* wait */
      }
    },

    homeButton() {
      // this is going trought pages
      if (this.$router.currentRoute.name === "Today") {
        this.$router.push("/compass");
      } else {
        this.$router.push("/");
      }
    },

    goDark() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.$store.commit("THEME_UPDATE", this.$vuetify.theme.dark);
    },

    goOptions() {
      this.$store.commit('PETITES_OPTIONS_TOGGLE',false);
      this.$store.commit('PLUSDECHOIX_TOGGLE',false);
      this.optionModal = true;
    },

    changeDate() {
      this.$store.commit("JOUR_SET", new Date(this.picker));
      this.picker = this.date.toISOString().substr(0, 10);
      this.calendarModal = false;
    },
    changeDateToToday() {
      this.$store.commit("JOUR_RESET");
      this.picker = this.date.toISOString().substr(0, 10);
      this.calendarModal = false;
    },

    addCity() {
      this.optionModal = false;
      this.$router.push("/cities");
      this.gpsModal = false;
    },

    setPosition(position) {
      var newGPScity = {
        addLabel: "Nouveau Lieu",
        addLat: position.coords.latitude,
        addLong: position.coords.longitude,
      };
      this.$store.commit("CITY_ADD", newGPScity);
      this.$store.commit("CITY_SET", 0);
    },
    // changeGPS(lat, long) {
    //     this.$store.commit("LAT_SET", lat);
    //     this.$store.commit("LONG_SET", long);
    // }
  },
  computed: {
    date() {
      return this.$store.state.date;
    },
    cities() {
      return this.$store.state.cities;
    },
    lat() {
      return this.$store.state.lat;
    },
    cityPick() {
      return this.$store.state.cityPick;
    },
    thedark() {
      return this.$store.state.options.global.thedark;
    },
    defaultHomePage() {
      return this.$store.state.options.global.defaultHomePage;
    },
    vuetifydark() {
      return this.$vuetify.theme.dark
    },
    autodark() {
      return this.$store.state.options.global.autodark;
    },
    autoNight(){
      return this.$store.state.options.compass.autoNight
    },
    petitesOptions() {
      return this.$store.state.petitesOptions;
    },
    // deviceDark() {
    //   return window.matchMedia("(prefers-color-scheme: dark)").matches;
    // },
  },
};
</script>

<style lang="scss">
* ::-webkit-scrollbar {
  display: none;
}
* {
  touch-action: manipulation;
  -webkit-user-select: text; /* Safari */
  -khtml-user-select: text; /* Konqueror HTML */
  -moz-user-select: text; /* Firefox */
  -ms-user-select: text; /* Internet Explorer/Edge */
  user-select: text; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  scrollbar-width: none;
}

#app {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.option-dialog {
  max-width: 500px;
}

.option-dialog-down {
  align-self: flex-end;
  max-width: 500px;
}
</style>
