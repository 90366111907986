var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"fixed":"","color":"transparent","flat":"","app":"","height":"56"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.homeButton}},[(this.$router.currentRoute.name === 'Cities' || this.$router.currentRoute.name === 'Infos')?_c('v-icon',[_vm._v("mdi-arrow-left")]):_vm._e(),(this.$router.currentRoute.name != 'Cities' && this.$router.currentRoute.name != 'Infos')?_c('svg',{staticStyle:{"width":"28px","height":"28px"},attrs:{"viewBox":"0,0,28,28","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink"}},[_c('circle',{attrs:{"cx":"14","cy":"14","r":"13.5","fill":_vm.$vuetify.theme.dark ? 'white' : '#383840'}}),_c('path',{attrs:{"transform":"translate(2,2) rotate(210,12,12)","fill":!_vm.$vuetify.theme.dark ? 'white' : '#121212'}},[_c('animate',{attrs:{"id":"animation-to-check","repeatCount":"indefinite","attributeName":"d","dur":"40s","attributeType":"auto","values":"\n  M 12 0 \n  C 5 0, 0 5, 0 12 \n  C 0 19, 5 24, 12 24 \n  C 5 24, 0 19, 0 12 \n  C 0 5, 5 0, 12 0\n  Z;\n\n\n  M 12 0 \n  C 5 0, 0 5, 0 12 \n  C 0 19, 5 24, 12 24 \n  C 19 24, 24 19, 24 12 \n  C 24 5, 19 0, 12 0\n  Z;\n\n\n  M 12 0 \n  C 19 0, 24 5 , 24 12 \n  C 24 19, 19 24, 12 24 \n  C 19 24, 24 19, 24 12 \n  C 24 5, 19 0, 12 0\n  Z;"}})])]):_vm._e()],1),(this.$router.currentRoute.name != 'Infos')?_c('v-toolbar-title',{on:{"click":function($event){_vm.gpsModal = true}}},[_c('a',[_vm._v(_vm._s(_vm.cities[_vm.cityPick].label))])]):_vm._e(),(this.$router.currentRoute.name === 'Infos')?_c('v-toolbar-title',[_c('a',[_vm._v(" Infos ")])]):_vm._e(),_c('v-spacer'),_c('v-dialog',{attrs:{"width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red lighten-2","icon":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-map-marker")])],1)]}}]),model:{value:(_vm.gpsModal),callback:function ($$v) {_vm.gpsModal=$$v},expression:"gpsModal"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-center"},[_vm._v(" LIEUX ")]),_c('v-col',[_c('v-radio-group',{model:{value:(_vm.citySelect),callback:function ($$v) {_vm.citySelect=$$v},expression:"citySelect"}},_vm._l((_vm.cities),function(city){return _c('v-radio',{key:city.id,attrs:{"label":city.label,"value":city.id}})}),1)],1),_c('v-divider'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.addCity}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-map-marker-plus ")]),_vm._v(" AJOUTER / MODIFIER ")],1),_c('v-spacer'),_c('v-spacer'),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.gpsModal = false}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar-search")])],1)]}}]),model:{value:(_vm.calendarModal),callback:function ($$v) {_vm.calendarModal=$$v},expression:"calendarModal"}},[_c('v-date-picker',{attrs:{"header-color":_vm.$vuetify.theme.dark ? '' : '#333',"full-width":"","scrollable":"","locale":"fr-fr","first-day-of-week":"1"},model:{value:(_vm.picker),callback:function ($$v) {_vm.picker=$$v},expression:"picker"}},[_c('v-btn',{attrs:{"text":"","color":"red lighten-2"},on:{"click":_vm.changeDateToToday}},[_vm._v(" Today ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.calendarModal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.changeDate}},[_vm._v(" OK ")])],1)],1),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","close-on-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-card',{staticClass:"text-left"},[_c('v-list',{staticClass:"mx-auto"},[_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.goDark}},[_c('v-list-item-title',[_vm._v("Dark Mode")]),_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-lightbulb-outline ")])],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.goOptions}},[_c('v-list-item-title',{attrs:{"left":""}},[_vm._v("Options")]),_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-tune ")])],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.addCity}},[_c('v-list-item-title',[_vm._v("Mes villes")]),_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-map-marker-multiple-outline ")])],1)],1),_c('v-list-item',{attrs:{"link":"","to":"/infos","push":""}},[_c('v-list-item-title',[_vm._v("À propos")]),_c('v-icon',[_vm._v(" mdi-information-variant ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"scrollable":"","content-class":_vm.petitesOptions ? 'option-dialog-down' : 'option-dialog'},model:{value:(_vm.optionModal),callback:function ($$v) {_vm.optionModal=$$v},expression:"optionModal"}},[_c('optionModal',{on:{"stopOptions":function($event){_vm.optionModal = false}}})],1)],1),_c('v-main',[_c('router-view',{on:{"showCalendarModal":function($event){_vm.calendarModal = true},"showOptionsModal":function($event){_vm.optionModal = true}}})],1),_c('v-bottom-navigation',{attrs:{"color":"primary","fixed":"","app":"","background-color":_vm.$vuetify.theme.dark ? '#121212' : '#FFF'}},[_c('v-btn',{attrs:{"to":"/calendar","push":"","height":"100%"}},[_c('v-icon',[_vm._v("mdi-calendar-blank-outline")])],1),_c('v-btn',{attrs:{"to":"/","push":"","height":"100%"}},[_c('v-icon',[_vm._v("mdi-timelapse")])],1),_c('v-btn',{attrs:{"to":"/compass","push":"","height":"100%"}},[_c('v-icon',[_vm._v("mdi-globe-model")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }