<template>
  <div class="lock-screen">
    <div class="content">
      <div class="list-container">
        <v-list three-line class="fond-list">
          <!-- ADD A CITY -->
          <v-card class="mx-auto my-5" max-width="300">
            <v-card-title>
              Nouveau Lieu :
            </v-card-title>
            <v-form ref="formAdd" v-model="validAdd">
              <div v-if="enableSearchMessage">
                <p
                  class="text-left pl-5 mb-0"
                  :class="!searchSuccess ? 'error--text' : 'info--text'"
                >
                  {{ searchMessage }}
                  <br />
                  <span
                    v-if="searchSuccess"
                    class="text-caption secondary--text"
                    >nominatim.openstreetmap.org</span
                  >
                </p>
              </div>
              <v-card-title class="small-height">
                <v-text-field
                  dense
                  hide-details="auto"
                  v-model="newCity.addLabel"
                  solo-inverted
                  label="Ville"
                  :rules="labelRules"
                  @keyup.enter="searchLocation(newCity.addLabel)"
                ></v-text-field>
              </v-card-title>
              <v-card-actions v-if="enableSearch">
                <v-spacer></v-spacer>
                <v-btn
                  class="pt-0 mt-0"
                  text
                  color="primary"
                  :loading="searchLoading"
                  @click="searchLocation(newCity.addLabel)"
                >
                  RECHERCHE
                </v-btn>
              </v-card-actions>
              <v-card-text class="fix-height">
                <v-row>
                  <v-col>
                    <v-text-field
                      ref="latia"
                      dense
                      hide-details="auto"
                      outlined
                      v-model="newCity.addLat"
                      label="Latitude"
                      :rules="numberRules"
                      type="number"
                      :loading="gpsLoading"
                    ></v-text-field>
                  </v-col>

                  <v-col>
                    <v-text-field
                      ref="longia"
                      dense
                      hide-details="auto"
                      outlined
                      v-model="newCity.addLong"
                      label="Longitude"
                      :rules="numberRules"
                      type="number"
                      :loading="gpsLoading"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <div v-if="gpsFailed">
                <h5 class="text-left error--text font-weight-bold pa-5 pb-0">
                  {{ gpsStatus.toUpperCase() }}
                </h5>
                <p class="text-left pa-5 pt-0">
                  Vérifiez que les paramètres de votre appareil autorisent la localisation. Vous pouvez également rechercher une ville.
                </p>
              </div>
            </v-form>

            <v-card-actions>
              <v-btn text @click="getGPS" :loading="gpsLoading">
                GPS
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="submitNewCity"
                :disabled="!validAdd"
              >
                AJOUTER
              </v-btn>

              <v-btn text color="error" @click="resetNewCity">
                RESET
              </v-btn>
            </v-card-actions>
          </v-card>

          <template v-for="(city, index) in cities">
            <!-- LIST OF CITY -->
            <v-card
              :key="index"
              class="mx-auto my-5"
              max-width="300"
              v-click-outside="{
                handler: (city) => (city.lock = true),
              }"
            >
              <v-form ref="formUpdate" v-model="validUpdate">
                <v-card-title :class="city.lock ? 'small-height' : ''">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="city.label"
                    solo-inverted
                    label="Nom"
                    :rules="labelRules"
                    :readonly="city.lock"
                    :append-icon="
                      cities.indexOf(city) != cityPick
                        ? 'mdi-checkbox-blank-outline'
                        : 'mdi-checkbox-marked-outline'
                    "
                    @click:append="$store.commit('CITY_SET', index)"
                  ></v-text-field>
                </v-card-title>
                <v-card-text class="fix-height">
                  <v-row v-if="!city.lock">
                    <v-col>
                      <v-text-field
                        dense
                        hide-details="true"
                        outlined
                        v-model="city.lat"
                        label="Latitude"
                        :rules="numberRules"
                        type="number"
                      ></v-text-field>
                    </v-col>

                    <v-col>
                      <v-text-field
                        dense
                        hide-details="true"
                        outlined
                        v-model="city.long"
                        label="Longitude"
                        :rules="numberRules"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>

              <v-card-actions class="action">
                <v-btn icon @click="changeOrder(cities.indexOf(city), true)">
                  <v-icon color="secondary">
                    mdi-chevron-up
                  </v-icon>
                </v-btn>
                <v-btn icon @click="changeOrder(cities.indexOf(city), false)">
                  <v-icon color="secondary">
                    mdi-chevron-down
                  </v-icon>
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                  text
                  color="primary accent-4"
                  @click="update(index, city)"
                  :disabled="!validUpdate"
                >
                  {{ city.lock ? "Modifier" : "Save" }}
                </v-btn>
                <v-btn
                  text
                  color="error"
                  @click="city.lock ? suppr(index) : restoration()"
                >
                  {{ city.lock ? "Effacer" : "Annuler" }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>

          <v-card class="mx-auto mt-15 mb-6" max-width="300">
            <v-btn
              class="my-2"
              :color="confirmCityReset ? 'red lighten-2' : ''"
              small
              text
              @click="confirmCityReset ? cityReset() : preCityReset()"
            >
              <v-icon class="mr-5">
                {{ confirmCityReset ? "mdi-restore-alert" : "mdi-restore" }}
              </v-icon>
              {{
                confirmCityReset
                  ? "Effacer toutes les villes ?"
                  : "Effacer toutes les villes"
              }}
            </v-btn>
          </v-card>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "cities",
  data() {
    return {
      labelRules: [
        (v) => !!v || "Nom du lieu requis",
        (v) => (v && v.length >= 3) || "Minimum 3 caractères",
      ],
      numberRules: [
        (v) => !!v || "Requis",
        (v) => v == Number(v) || "Ce doit être un chiffre",
      ],

      validAdd: true,
      validUpdate: true,

      newCity: { addLabel: "", addLat: "", addLong: "" },

      restore: [],

      gpsStatus: "off", // switch between "off" "on" "loading" "fail"
      gpsLoading: false,
      gpsFailed: false,

      youhavetosave: false,
      confirm: false,

      enableSearch: true,
      searchLoading: false,
      enableSearchMessage: false,
      searchSuccess: false,
      searchMessage: "",

      confirmCityReset: false,
    };
  },

  //*********************         MOUNTED, BEFORE DESTROY / ROUTE LEAVE, WATCH         ************************************************/
  mounted: function() {
    this.restore = this.cities.map((a) => ({ ...a }));
  },
  beforeDestroy: function() {
    this.restoration();
  },
  beforeRouteLeave(to, from, next) {
    //Check if we are adding a city,
    let editing =
      this.newCity.addLabel != "" ||
      this.newCity.addLat != "" ||
      this.newCity.addLong != "";

    if (editing) {
      if (window.confirm("Abandonner le nouveau lieu ?")) {
        next();
      } else {
        next(false);
      }
      return;
    }

    //Check if we are editing a city,

    if (!this.cities.every((d) => d.lock === true)) {
      for (var i = 0; i < this.cities.length; i++) {
        for (var propertyName in this.cities[i]) {
          if (this.cities[i][propertyName] !== this.restore[i][propertyName]) {
            if (propertyName === "lock") {
              next();
              break;
            }
            if (window.confirm("Abandonner les changements ?")) {
              next();
            } else {
              next(false);
            }
            break;
          }
        }
      }
    } else {
      next();
    }
  },

  watch: {
    // newCity: function() {
    //   this.gpsFailed = false;
    // },
  },

  //*********************         COMPUTED         *****************************************************************************/
  computed: {
    //STORE ACCESS
    cities() {
      return [...this.$store.state.cities];
    },
    cityPick() {
      return this.$store.state.cityPick;
    },
  },

  //*********************         METHODS         *******************************************************************************/
  methods: {
    success(position) {
      let arrondie = 10000;
      const dalatitude =
        Math.round(position.coords.latitude * arrondie) / arrondie;
      const dalongitude =
        Math.round(position.coords.longitude * arrondie) / arrondie;

      this.gpsLoading = false;
      this.gpsFailed = false;
      this.enableSearch = false;
      this.youhavetosave = true;
      this.newCity.addLat = dalatitude;
      this.newCity.addLong = dalongitude;
      this.newCity.addLabel = "Lieu " + this.cities.length;
      this.gpsStatus = "on";

      this.searchLoading = true;
      let apiPath = "https://nominatim.openstreetmap.org/reverse.php";
      let params = {
        lat: position.coords.latitude,
        lon: position.coords.longitude,
        format: "jsonv2",
      };

      axios
        .get(apiPath, { params: params })
        .then((response) => {
          let JSONdata = response.data.address;
          console.log(JSONdata);
          this.newCity.addLabel = JSONdata.city
            ? JSONdata.city
            : JSONdata.village;
          this.searchLoading = false;
          this.enableSearch = false;
        })
        .catch(() => {
          this.searchSuccess = false;
          this.searchLoading = false;
          this.searchMessage = "Not found";
          this.enableSearchMessage = true;
        });
    },

    error() {
      this.gpsLoading = false;
      this.gpsStatus = "Erreur...";
      this.gpsFailed = true;
      this.$refs.formAdd.validate();
      this.enableSearch = true;
    },
    getGPS() {
      if (!navigator.geolocation) {
        this.gpsLoading = false;
        this.gpsStatus = "Geolocation is not supported";
      } else {
        this.gpsStatus = "Chargement...";
        this.gpsLoading = true;

        setTimeout(
          function() {
            navigator.geolocation.getCurrentPosition(this.success, this.error);
          }.bind(this),
          1000
        );
      }
    },

    searchLocation(theCity) {
      this.gpsFailed = false;
      this.searchSuccess = false;
      if (!theCity || theCity.split("").length < 5) {
        this.enableSearchMessage = true;
        this.searchMessage = "Un peu plus spécifique...";
        return;
      }

      this.searchLoading = true;
      let apiPath = "https://nominatim.openstreetmap.org/search.php";
      let params = {
        q: theCity,
        format: "json",
        limit: 1,
      };

      axios
        .get(apiPath, { params: params })
        .then((response) => {
          let JSONData = response.data[0];

          let arrondie = 10000;
          this.newCity.addLat = Math.round(JSONData.lat * arrondie) / arrondie;
          this.newCity.addLong = Math.round(JSONData.lon * arrondie) / arrondie;
          this.newCity.addLabel = JSONData.display_name.split(",")[0];
          this.searchMessage = JSONData.display_name;
          this.searchLoading = false;
          this.enableSearchMessage = true;

          this.searchSuccess = true;
          console.log(JSONData);
        })
        .catch(() => {
          this.searchSuccess = false;
          this.searchLoading = false;
          this.searchMessage = "Not found";
          this.enableSearchMessage = true;
        });
    },

    // resetValidation() {
    //   this.$refs.formAdd.resetValidation();
    // },

    suppr(index) {
      if (confirm("Do you really want to delete?")) {
        this.$store.commit("CITY_DELETE", index);
        this.restore = this.cities.map((a) => ({ ...a }));
      }
    },
    update(index, city) {
      var restore = [...this.restore];
      var cities = this.cities;
      if (!city.lock) {
        city.lock = !city.lock;
        this.$store.commit("CITY_UPDATE", { index: index, update: city });

        this.restore = cities.map((a) => ({ ...a }));
        this.$refs.formUpdate.resetValidation();
      } else {
        if (!cities.every((d) => d.lock === true)) {
          for (var i = 0; i < cities.length; i++) {
            for (var propertyName in cities[i]) {
              if (cities[i][propertyName] !== restore[i][propertyName]) {
                if (propertyName === "lock") {
                  break;
                }
                if (!confirm("Ne pas enregistrer les changements?")) {
                  return;
                }
                break;
              }
            }
          }
        }
        this.restoration();
        city.lock = !city.lock;
      }
    },
    restoration() {
      this.cities.forEach((d) => (d.lock = true));
      this.cities.forEach((d, i) => (d.lat = this.restore[i].lat));
      this.cities.forEach((d, i) => (d.long = this.restore[i].long));
      this.cities.forEach((d, i) => (d.label = this.restore[i].label));
    },

    submitNewCity() {
      this.$store.commit("CITY_ADD", this.newCity);
      this.newCity = { addLabel: "", addLat: "", addLong: "" };
      this.restore = this.cities.map((a) => ({ ...a }));
      this.gpsFailed = false;
      this.enableSearchMessage = false;
      this.enableSearch = true;
      this.searchSuccess = false;
      this.$refs.formAdd.resetValidation();
    },
    resetNewCity() {
      this.gpsFailed = false;
      this.enableSearch = true;
      this.enableSearchMessage = false;
      this.newCity = { addLabel: "", addLat: "", addLong: "" };
      this.$refs.formAdd.resetValidation();
    },
    changeOrder(index, up) {
      if (index === 0 && up) {
        return;
      } else if (index === this.cities.length - 1 && !up) {
        return;
      } else if (up) {
        this.$store.commit("CITY_MOOVE_UP", index);
        this.restore = this.cities.map((a) => ({ ...a }));
      } else {
        this.$store.commit("CITY_MOOVE_DOWN", index);
        this.restore = this.cities.map((a) => ({ ...a }));
      }
    },

    preCityReset() {
      this.confirmCityReset = true;
    },

    cityReset() {
      if (window.confirm("Toutes vos villes seront supprimées")) {
        localStorage.removeItem("cities");
        this.$store.dispatch("resetCities");
        this.restore = this.cities.map((a) => ({ ...a }));
        this.confirmCityReset = false;
      } else {
        this.confirmCityReset = false;
        return;
      }
    },
  },
};
</script>

<style scoped>
.lock-screen {
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: fixed;
  padding-bottom: 112px;
}

.content {
  height: 100%;
  padding: 4px 16px 16px;
  display: inline;
  margin: auto;
  max-width: 100%;
  /* background-color: rgb(68, 68, 216); */
}

.retour {
  padding-left: 2em;
}

.list-container {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  position: fixed;
  padding-bottom: 120px;
}

.fix-height {
  padding-top: 0;
  padding-bottom: 0;
}
.small-height {
  padding-bottom: 0;
}
.fond-list {
  background-color: transparent;
}
</style>
